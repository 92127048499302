.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* p{
  color: red;
} */
/* 
/* Share Picture-Text Page */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css');
.line-height-18 { line-height: 18px;}
.line-height-24 { line-height: 24px;}
.mt-120 {margin-top: 120px;}
.font-size-12 { font-size: 12px;}
.font-size-14 { font-size: 14px;}
.font-size-15 { font-size: 15px;}
.font-size-16 { font-size: 16px;}
.font-size-18 { font-size: 18px;}
.font-size-24 { font-size: 24px;}
.font-size-60 { font-size: 60px; line-height: 60px;}
.mr-70 { margin-right: 70px;}

.min-height-225 { min-height: 225px;}
.border-radius-10 { border-radius: 10px !important;}
.font-weight-medium { font-weight: 500;}
.font-weight-bold { font-weight: 700;}
.grey-text-color { color: #7F8284;}
.green-text-color { color: #019442;}
.black-text-color { color: #30374B;}

.box-outer .badge {font-size: 10px ; padding: 4px 15px; font-weight: 500; vertical-align: top;}

.golfclix-logo { width: 130px;}
.box-inner {box-shadow: 0 0 20px rgba(0, 0, 0, 0.08); padding: 15px; border-radius: 10px; font-family: 'Roboto', sans-serif; font-size: 18px;}
.footer-bg { background-color: #F9F9F9; border-bottom: 5px solid #019442;}
.footer-icon span { border:1px solid #019442; border-radius: 10px; display: block; width: 44px; height: 44px; line-height: 40px; text-align: center;}
.footer-icon span i { font-size: 30px;}
.footer-bg a { text-decoration: none; color: #000;}
.footer-bg a:hover {text-decoration: none; color: #019442;}
.ads { background-color: #009736; color: #fff; } 
.ads:hover { background-color: #000; color: #fff; text-decoration: none;}

.badge-date { background-color: #51546A; color: #fff; } 
.badge-date:hover { background-color: #009736; color: #fff; text-decoration: none;}

.result-duration span { width: 150px;}
.result-duration em { font-style: normal; font-size: 19px;}
.result-count sup { font-weight: normal; font-size: 14px; color: #7F8284; position: absolute; top: 10px; margin-left: 5px;}
.border-result { border-left: 1px solid #dee2e6;}

@media (max-width: 767px) {

  .min-height-225 { min-height: inherit;}
  .mt-120 { margin-top: 40px;}
  .border-result { border-left: none;}
  .result-duration {border-top: 1px solid #dee2e6;}

}

/* End Share Picture-Text Page */

