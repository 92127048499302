@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,400i,700,700i,900);
@import url(https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://unpkg.com/purecss@1.0.0/build/pure-min.css);
@import url(https://use.fontawesome.com/releases/v5.0.8/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* p{
  color: red;
} */
/* 
/* Share Picture-Text Page */
.line-height-18 { line-height: 18px;}
.line-height-24 { line-height: 24px;}
.mt-120 {margin-top: 120px;}
.font-size-12 { font-size: 12px;}
.font-size-14 { font-size: 14px;}
.font-size-15 { font-size: 15px;}
.font-size-16 { font-size: 16px;}
.font-size-18 { font-size: 18px;}
.font-size-24 { font-size: 24px;}
.font-size-60 { font-size: 60px; line-height: 60px;}
.mr-70 { margin-right: 70px;}

.min-height-225 { min-height: 225px;}
.border-radius-10 { border-radius: 10px !important;}
.font-weight-medium { font-weight: 500;}
.font-weight-bold { font-weight: 700;}
.grey-text-color { color: #7F8284;}
.green-text-color { color: #019442;}
.black-text-color { color: #30374B;}

.box-outer .badge {font-size: 10px ; padding: 4px 15px; font-weight: 500; vertical-align: top;}

.golfclix-logo { width: 130px;}
.box-inner {box-shadow: 0 0 20px rgba(0, 0, 0, 0.08); padding: 15px; border-radius: 10px; font-family: 'Roboto', sans-serif; font-size: 18px;}
.footer-bg { background-color: #F9F9F9; border-bottom: 5px solid #019442;}
.footer-icon span { border:1px solid #019442; border-radius: 10px; display: block; width: 44px; height: 44px; line-height: 40px; text-align: center;}
.footer-icon span i { font-size: 30px;}
.footer-bg a { text-decoration: none; color: #000;}
.footer-bg a:hover {text-decoration: none; color: #019442;}
.ads { background-color: #009736; color: #fff; } 
.ads:hover { background-color: #000; color: #fff; text-decoration: none;}

.badge-date { background-color: #51546A; color: #fff; } 
.badge-date:hover { background-color: #009736; color: #fff; text-decoration: none;}

.result-duration span { width: 150px;}
.result-duration em { font-style: normal; font-size: 19px;}
.result-count sup { font-weight: normal; font-size: 14px; color: #7F8284; position: absolute; top: 10px; margin-left: 5px;}
.border-result { border-left: 1px solid #dee2e6;}

@media (max-width: 767px) {

  .min-height-225 { min-height: inherit;}
  .mt-120 { margin-top: 40px;}
  .border-result { border-left: none;}
  .result-duration {border-top: 1px solid #dee2e6;}

}

/* End Share Picture-Text Page */


body {
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding-right: 0 !important; }

body.modal-open {
  padding-right: 17px !important; }

body.modal-open .fixed-header, body.modal-open .inner-header, body.modal-open .inner-header .sticky-top {
  left: -17px;
  padding-left: 33px; }

form {
  position: relative; }

p {
  color: #000;
  line-height: 26px; }

section, footer {
  padding: 60px 0;
  clear: both; }

.clearfix {
  clear: both;
  float: left;
  width: 100%; }

a:hover {
  color: #00a8a0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  text-transform: uppercase; }

dl, ol, ul {
  margin: 0;
  padding: 0; }

.line-height {
  line-height: 36px !important; }

.termsandconditions h4 {
  color: #39579c; }

.termsandconditions h5 {
  color: #506cab;
  text-transform: capitalize; }

.main-header {
  position: relative;
  border-bottom: 1px solid rgba(247, 246, 246, 0.3);
  z-index: 9;
  height: 74px; }

.top-bottom-space {
  padding: 150px 0; }

.image-center {
  overflow: hidden; }

.image-center img {
  transform: translateY(-50%);
  top: 50%;
  position: relative;
  width: 100%; }

.main-header button:focus {
  outline: 0;
  background: none; }

.disable-block, .disable-block-color {
  position: relative; }

.disable-block-color:before {
  background: rgba(247, 246, 246, 0.3); }

.disable-block:before, .disable-block-color:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  cursor: not-allowed;
  height: 100%;
  width: 100%;
  z-index: 9999; }

.pointer {
  cursor: pointer; }

.main-header .navbar-collapse {
  flex-grow: unset; }

.main-header .bg-dark {
  background-color: transparent !important;
  z-index: 9;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.main-header .btn {
  text-transform: none;
  color: #fff !important;
  line-height: 40px; }

.main-header .nav-link {
  font-size: 18px;
  color: #fff !important; }

.main-header .nav-item {
  margin-right: 20px;
  text-align: center;
  position: relative; }

.main-heading {
  color: #233d7b;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  text-align: left;
  padding-bottom: 7px;
  margin-bottom: 25px; }

.main-header .navbar-toggler-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAlCAYAAAA9ftv0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZFNzI4N0JEMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZFNzI4N0JFMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkU3Mjg3QkIyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkU3Mjg3QkMyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ykQ3HAAABLklEQVR42mL8//8/AxD0AHEkEPMwDG7wHYjXAnEuI9Dh7UBGBcPQAtNBDn8JZIgNMYd/ZwISzxmGHngJCnE7IOMAEDMOIYd7M0IzpzoQRwAx7yB38DcgXgPEl2AOH3KAiWGIglGHjzp81OGjDh91OBiwQGk9II6iUbP2LRAvAOL71DQUVHO6AOndNA6gn0BsDsQXqenwy0Bahw6xuwuI3amZxkXplCwlqJ05Z9LJ4VOpnTnrgZgZmjlp0ax9B8SzgXgWtTMnMp+DBg7/QYvoG22Pjzp81OGjDh91+KjDB7Q9PthGsu5Dmwi/8dWc9kB6P8PgGzsENbetgfgzrqQykWFwDnjqAnEOvjQuOYiTsiI+h88bxA5fgC9zVgIxK8PgmgN6BMRtQHwMlwKAAAMAszFG6laeJF0AAAAASUVORK5CYII=);
  height: 22px;
  width: 24px; }

.main-heading:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 1px;
  width: 67px; }

.main-heading:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 1px;
  width: 50px; }

.user-pic .nav-link {
  padding-left: 0;
  padding-right: 0;
  line-height: 40px; }

.user-pic {
  margin-right: 25px; }

.heading-2 {
  text-align: center; }

.heading-2:after {
  content: "";
  position: absolute;
  left: 46%;
  bottom: 0;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 1px;
  width: 67px; }

.heading-2:before {
  content: "";
  position: absolute;
  left: 46%;
  bottom: -4px;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 1px;
  width: 50px; }

.btn, a.btn {
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  background-color: #00a8a0;
  border: 0;
  color: #fff;
  -webkit-border: 0;
  -moz-border: 0;
  padding-bottom: 0;
  padding-top: 0;
  min-width: 160px;
  text-transform: uppercase;
  padding-left: 30px;
  padding-right: 30px; }

.btn:hover, .btn:focus, a.btn:hover, a.btn:focus {
  background-color: #233d7b;
  box-shadow: 0px 0px 0px 1px #fff;
  color: #fff; }

.btn:active {
  background: #233d7b !important; }

.btn:active:focus, btn:focus {
  box-shadow: none !important; }

.btn-primary {
  background-color: #233d7b; }

.btn-primary:hover {
  background-color: #00a8a0; }

.btn .fa, a.btn .fa {
  padding-right: 8px; }

button:focus, a:focus {
  outline: none; }

button.link {
  background: none;
  border: 0; }

button.kep-login-facebook {
  height: 40px;
  line-height: 40px;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border: 0;
  color: #fff;
  -webkit-border: 0;
  -moz-border: 0;
  padding-bottom: 0;
  padding-top: 0;
  min-width: 160px;
  text-transform: none !important;
  font-weight: normal !important;
  padding-left: 30px;
  padding-right: 30px; }

.linkedin-sdk {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important; }

.container-small {
  max-width: 970px;
  margin: 0 auto; }

.banner-pic {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; }

.home-banner .banner-content {
  width: 100%;
  height: 100%;
  position: relative; }

.caption {
  height: 100%;
  text-align: center;
  padding-top: 90px; }

.home-banner {
  height: 550px;
  float: left;
  width: 100%;
  position: relative;
  margin-top: -77px;
  background-image: url(/static/media/banner-img.307ec2f4.jpg); }

.banner-title {
  font-size: 34px;
  font-weight: 900;
  color: #fff;
  padding: 20px 0;
  text-transform: none;
  line-height: 50px;
  letter-spacing: 2.7px;
  margin: inherit; }

.banner-title span {
  font-size: 23px;
  font-weight: 300;
  display: block;
  text-transform: none;
  letter-spacing: 0.7px; }

.home-form-block {
  background-color: rgba(240, 239, 239, 0.1);
  padding: 20px;
  display: inline-block;
  box-shadow: 0px 0px 0px 2px rgba(35, 61, 130, 0.1); }

/*Sticky Header CSS*/
.fixed-header .header-logo, .sticky-logo, .sticky-form-block {
  display: none; }

.fixed-header .sticky-logo, .fixed-header .sticky-form-block {
  display: block; }

.fixed-header .sticky-form-block .sticky-search-block:after {
  border: 0;
  top: 2px;
  color: #00a8a0; }

.fixed-header .sticky-form-block .sticky-location-block:after {
  border: 0;
  top: 2px;
  color: #00a8a0; }

.fixed-header .form-control, .sticky-search-block input, .sticky-location-block input {
  padding-left: 45px !important; }

.sticky-form-block {
  margin: 0 auto; }

.sticky-form-block .sticky-location-block, .sticky-form-block .sticky-search-block {
  float: left;
  width: 285px;
  position: relative;
  margin-right: 20px; }

.sticky-form-block .sticky-location-block:after, .sticky-form-block .sticky-search-block:after {
  position: absolute;
  font-family: 'FontAwesome';
  font-size: 20px;
  left: 20px;
  top: 6px;
  color: #b2b2b2;
  line-height: 40px;
  border-right: 1px solid #cdcccd;
  height: 40px;
  width: 40px; }

.sticky-form-block .sticky-search-block:after {
  content: '\f002'; }

.sticky-form-block .sticky-location-block:after {
  content: '\f041'; }

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  z-index: 9; }

/*Inner Header*/
.inner-header .sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  z-index: 9;
  padding-bottom: 0;
  padding-top: 0;
  border-bottom: 1px solid rgba(247, 246, 246, 0.3); }

.main-header .nav-item {
  padding: 9px 20px 9px 10px;
  margin-right: 0; }

.navbar {
  padding-bottom: 0;
  padding-top: 0; }

.main-header .nav-link {
  line-height: 40px;
  position: relative; }

.inner-header .nav-link span {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ff0b0b;
  font-size: 11px;
  top: 5px;
  right: 0;
  text-align: center;
  line-height: 20px; }

.inner-header .sticky-form-block .sticky-search-block:after {
  border: 0;
  top: 2px;
  color: #00a8a0; }

.inner-header .sticky-form-block .sticky-location-block:after {
  border: 0;
  top: 2px;
  color: #00a8a0; }

.inner-header .sticky-form-block {
  display: block; }

.inner-header .form-control {
  padding-left: 50px; }

.profile-item.show {
  background: #18346d; }

.profile-item {
  position: relative; }

.profile-item > a:after, .profile-item > button.link:after {
  position: absolute;
  font-family: 'FontAwesome';
  font-size: 10px;
  right: -10px;
  top: 10px;
  color: #fff;
  content: '\F078';
  border: 0; }

.notification-item li:after, .notification-item button:after {
  display: none; }

.notification-item li {
  padding-left: 0 !important;
  text-align: left; }

.notification-item .dropdown-menu {
  /*overflow-x: hidden;*/
  left: -122px !important;
  max-height: 150px;
  padding: 0 !important; }

.notification-item .dropdown-menu {
  min-height: inherit;
  max-height: 350px;
  padding-right: 0 !important; }

.notification-item .dropdown-menu ul {
  min-height: inherit;
  max-height: 345px;
  overflow-y: auto; }

.notification-item .dropdown-menu ul li.unread {
  background: #f2f2f2;
  color: #00a8a0;
  cursor: pointer; }

.notification-item .dropdown-menu ul li {
  font-size: 14px;
  padding: 10px !important;
  border-bottom: 1px solid #e9ebf2 !important; }

.notification-item .dropdown-menu .all-read {
  background: #e4ebf1;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  height: 32px; }

.second-header {
  background-color: #f2f2f2;
  padding: 0;
  height: 57px;
  border-bottom: 1px solid #e9ebf2;
  position: static; }

.second-header .navbar-nav .nav-link {
  color: #233d7b;
  font-size: 15px;
  padding: 0 26px;
  text-transform: uppercase; }

.second-header .navbar-nav .nav-link:hover {
  color: #081a44; }

.second-header .navbar-nav .nav-item {
  border-right: 1px solid #dde0e6;
  padding: 0;
  line-height: 58px; }

.second-header .navbar-nav .nav-item:first-child {
  border-left: 1px solid #dde0e6; }

.nav-link.btn {
  margin-top: 5px; }

.second-header .navbar-nav .nav-link.active {
  background-color: #00a8a0;
  color: #fff; }

.second-header .navbar-nav .dropdown-menu {
  z-index: 2;
  line-height: 26px;
  min-width: 240px;
  border: 0;
  border-radius: 0;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  padding: 0;
  overflow: auto;
  height: 300px; }

.second-header .navbar-nav .dropdown-menu .dropdown-item {
  position: relative; }

.second-header .navbar-nav .dropdown-menu .dropdown-item:after {
  position: absolute;
  content: "";
  left: 10px;
  top: 18px;
  height: 6px;
  width: 6px;
  background-color: #000;
  border-radius: 50%; }

.second-header .navbar-nav .dropdown-item {
  padding-left: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
  padding-top: 8px; }

.second-header .navbar-nav .dropdown-item.active, .second-header .navbar-nav .dropdown-item:active {
  background: none;
  color: #000; }

.profile-item .dropdown-menu {
  left: -110px;
  top: -9px;
  border-radius: 0;
  border: 0;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  padding: 5px 15px;
  min-width: 200px; }

/*Category Home Banner Slider*/
.category-slider {
  background-color: rgba(247, 246, 246, 0.1);
  border-top: 1px solid rgba(247, 246, 246, 0.1);
  border-bottom: 1px solid rgba(247, 246, 246, 0.1);
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  margin-top: -130px; }

.category-box {
  min-height: 130px;
  border-left: 1px solid rgba(247, 246, 246, 0.1);
  text-align: center;
  padding: 10px 0;
  transition: background-color 0.5s ease;
  cursor: pointer; }

.category-box a {
  text-decoration: none; }

.category-box:first-child {
  border-right: 1px solid rgba(247, 246, 246, 0.1); }

.category-box p {
  color: #fff;
  padding-top: 10px;
  margin-bottom: 0;
  line-height: normal;
  font-size: 14px; }

.category-icon {
  height: 52px;
  width: 52px;
  display: inline-block;
  background-repeat: no-repeat; }

.category-icon.beauty-icon {
  background-position: -22px -22px; }

.category-icon.repair-icon {
  background-position: -92px -22px; }

.category-icon.event-icon {
  background-position: -162px -22px; }

.category-icon.restaurant-icon {
  background-position: -232px -22px; }

.category-icon.photo-icon {
  background-position: -302px -22px; }

.category-icon.travel-icon {
  background-position: -368px -22px; }

.category-box:hover {
  background: #fff; }

.category-box:hover p {
  color: #000; }

.category-box:hover .category-icon.beauty-icon {
  background-position: -22px -97px; }

.category-box:hover .category-icon.beauty-icon {
  background-position: -22px -97px; }

.category-box:hover .category-icon.repair-icon {
  background-position: -92px -97px; }

.category-box:hover .category-icon.event-icon {
  background-position: -162px -97px; }

.category-box:hover .category-icon.restaurant-icon {
  background-position: -232px -97px; }

.category-box:hover .category-icon.photo-icon {
  background-position: -302px -97px; }

.category-box:hover .category-icon.travel-icon {
  background-position: -368px -97px; }

.category-icon-hide-mobile {
  display: none;
  margin: 14px auto;
  text-align: center; }

.slick-prev:before {
  content: '\f053' !important;
  font-family: 'FontAwesome' !important;
  font-size: 14px !important; }

.slick-next:before {
  content: '\f054' !important;
  font-family: 'FontAwesome' !important;
  font-size: 14px !important; }

.slick-prev, .slick-next {
  border: 1px solid #fff !important;
  height: 38px !important;
  width: 38px !important;
  text-align: center !important; }

.slick-next {
  right: -25px !important;
  z-index: 1; }

.slick-prev {
  left: -25px !important;
  z-index: 1; }

.tabbing-block .nav-tabs .nav-link {
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border: 0;
  -webkit-border: 0;
  -moz-border: 0;
  padding: 0px 6px;
  font-size: 16px;
  display: block !important;
  min-width: 160px;
  position: relative;
  font-weight: normal;
  box-shadow: 0 0 21.4px 2.6px rgba(0, 0, 0, 0.2); }

.tabbing-block .nav-tabs .nav-item.show .nav-item, .tabbing-block .nav-tabs .nav-item.active {
  color: #fff;
  background-color: #00a8a0;
  box-shadow: none; }

.tabbing-block .nav-tabs .nav-item .nav-link.active:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #00a8a0;
  position: absolute;
  left: 0;
  bottom: -9px;
  content: "";
  right: 0;
  text-align: center;
  margin: 0 auto;
  z-index: 1; }

.tabbing-block .nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background: #00a8a0;
  position: relative;
  border: 0; }

.tabbing-block .nav-tabs .nav-item {
  min-width: 50%;
  cursor: pointer; }

.tabbing-block .tab-left .nav-link {
  border-radius: 50px 0px 0px 50px !important; }

.tabbing-block .tab-right .nav-link {
  border-radius: 0px 50px 50px 0px !important; }

.tabbing-block {
  margin-top: -30px;
  position: relative;
  float: left;
  width: 100%;
  display: block; }

/*Form CSS*/
.search-block {
  float: left;
  width: 458px;
  position: relative; }

.location-block {
  float: left;
  width: 212px;
  position: relative;
  margin: 0 20px; }

.search-block::after, .location-block::after {
  position: absolute;
  font-family: 'FontAwesome';
  font-size: 20px;
  left: 10px;
  top: 4px;
  color: #b2b2b2;
  line-height: 40px;
  border-right: 1px solid #cdcccd;
  height: 32px;
  width: 36px;
  line-height: 32px; }

.search-block::after {
  content: '\f002'; }

.location-block::after {
  content: '\f041'; }

.home-form-block .btn {
  float: left;
  width: 212px;
  position: relative;
  text-transform: none; }

.home-form-block .form-control {
  padding-left: 60px; }

.form-control {
  font-size: 18px;
  color: rgba(4, 2, 3, 0.8);
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 0;
  -webkit-border: 0;
  -moz-border: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-top: 0; }

/*Popular Sevices CSS Start*/
.popular-service-block {
  background-color: #f5f5f5;
  clear: both;
  position: relative;
  display: none; }

.popular-box {
  margin-bottom: 40px;
  position: relative; }

.popular-box .card {
  border: none; }

.popular-box .card-header span {
  position: absolute;
  bottom: 0;
  left: 10px;
  background-color: #00a8a0;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 700;
  color: #fff; }

.popular-box .card-body {
  padding: 15px 0 0 0; }

.popular-box .card-title {
  font-size: 17px;
  text-align: left;
  font-weight: 700;
  color: #000;
  padding-left: 10px;
  cursor: pointer; }

.popular-box .card-footer {
  color: #fff;
  background-color: #00a8a0;
  border: none;
  border-radius: 0 !important;
  padding: 10px;
  text-align: center; }

.popular-box .popular-location {
  font-size: 14px;
  float: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px; }

.popular-box .popular-location span {
  font-size: 18px;
  margin-right: 4px;
  vertical-align: middle; }

.popular-box .popular-chat {
  margin: 0 auto;
  text-align: center;
  display: inline-block; }

.popular-box .popular-review {
  font-size: 14px;
  float: right;
  line-height: 24px; }

.popular-box .popular-review span {
  color: #ff7700;
  margin-right: 4px;
  font-size: 11px; }

.popular-box .popular-review .star-ratings {
  line-height: 24px;
  float: left;
  display: inline-flex !important;
  padding-right: 6px; }

.star-container {
  vertical-align: top !important;
  line-height: 18px;
  padding-right: 0 !important; }

.review-detail .star-ratings {
  line-height: 20px; }

/*Beauty Salon CSS Start*/
.services-block {
  background-color: #fff; }

.services-block .container {
  padding: 0; }

.services-block button.slick-arrow.slick-prev, .services-block button.slick-arrow.slick-next {
  background-color: #00a8a0;
  border: 0 !important; }

.services-block button.slick-arrow.slick-prev {
  left: -5px !important;
  z-index: 1; }

.services-block button.slick-arrow.slick-next {
  right: -5px !important;
  z-index: 1; }

.slick-prev:hover, .slick-next:hover {
  background: #233d7b !important; }

.beauty-box .card {
  border: none;
  cursor: pointer; }

.beauty-box .card-header, .popular-box .card-header {
  position: relative;
  padding: 0;
  border: 0;
  overflow: hidden;
  text-align: center; }

.popular-box .card-header {
  height: 170px; }

.popular-box .card-header img {
  width: 100%; }

.beauty-box .card-header::after {
  position: absolute;
  left: 10px;
  content: "";
  top: 10px;
  bottom: 10px;
  right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5); }

.beauty-box .card-header img, .popular-box .card-header img {
  max-width: 100%;
  transition: all 0.3s; }

.beauty-box .card-header:hover img, .popular-box .card-header:hover img {
  max-width: 100%;
  transform: scale(1.3); }

.beauty-box .card-title {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #00a8a0;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  position: relative;
  height: 60px; }

.beauty-box .card-title span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

/*HOW IT WORKES CSS START*/
.how-it-works-block {
  background-color: #f2f2f2;
  clear: both; }

.how-it-works-block ul {
  margin: 0;
  padding: 0; }

.how-it-works-block ul li {
  text-align: center;
  color: #1aaea8;
  font-size: 16px;
  font-weight: 700;
  list-style: none;
  width: 33%;
  display: inline-block;
  line-height: normal;
  vertical-align: top; }

.step-pic {
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: center; }

.join-now-block {
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  float: left;
  width: 100%;
  padding: 30px 0; }

.join-now-block h3 {
  color: #fff;
  font-size: 25px;
  text-transform: none;
  float: left;
  margin-bottom: 0;
  line-height: 44px; }

.join-now-block .btn {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  height: 44px;
  line-height: 44px; }

/*Client Testimonials CSS Start*/
.testimonial-box {
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 0;
  margin-top: 10px;
  margin-bottom: 10px; }

.testimonial-box {
  position: relative;
  overflow: hidden; }

.testimonial-box .card {
  max-width: 100%;
  transition: all 0.3s;
  border: none; }

.testimonial-box .card:hover {
  max-width: 100%;
  transform: scale(1.1); }

.testimonial-box .card:hover .card-header span {
  color: #00a8a0;
  font-size: 40px;
  display: block;
  padding-bottom: 10px;
  text-align: left; }

.testimonial-box .card:hover {
  max-width: 100%;
  transform: scale(1.1); }

.testimonial-box .card-header span {
  color: #bac4d8;
  font-size: 40px;
  display: block;
  padding-bottom: 10px;
  text-align: left; }

.testimonial-box .card-header {
  color: #000;
  text-align: left;
  border-bottom: 1px solid #d1d8e5;
  padding: 20px 40px 30px 40px; }

.testimonial-box .card-title {
  padding: 20px 40px; }

.testimonial-box .card-title img {
  float: left;
  padding-right: 20px; }

.testimonial-box .card-title .c-name {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #193d7f;
  padding-top: 10px;
  display: inline-block; }

.testimonial-box .card-title .c-name span {
  color: #959595;
  font-size: 16px;
  font-weight: normal;
  display: block; }

.testimonials-block {
  padding: 60px 0 100px 0; }

.testimonials-block .slick-dots, .howit-works-slider .slick-dots {
  bottom: -40px; }

.testimonials-block .slick-dots li, .howit-works-slider .slick-dots li {
  width: 12px;
  margin-top: 20px; }

.testimonials-block .slick-dots li.slick-active button:before, .howit-works-slider .slick-dots li.slick-active button:before {
  color: #193d7f !important; }

.testimonials-block .slick-dots li button:before, .howit-works-slider .slick-dots li button:before {
  opacity: 9 !important;
  color: #00aba1 !important;
  font-size: 14px !important; }

.testimonials-block .slick-arrow {
  display: none !important; }

/*Mobile App CSS Start*/
.app-detail-box {
  color: #fff; }

.mobile-app-block {
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  padding-bottom: 0;
  padding-top: 20px; }

.mobile-app-block .app-detail-box h3 {
  font-size: 30px; }

.mobile-app-block .app-detail-box p {
  font-size: 18px;
  color: #fff; }

.mobile-app-block .app-detail-box .get-notify {
  margin-top: 45px; }

.mobile-app-block .app-detail-box .get-notify h4 {
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: none; }

.mobile-app-block .app-detail-box .get-notify .form-control {
  border: 1px solid #233d7b;
  padding-left: 20px;
  max-width: 340px;
  float: left; }

.mobile-app-block .app-detail-box .get-notify form {
  float: left;
  width: 100%;
  margin-bottom: 50px; }

.mobile-app-block .app-detail-box .btn {
  box-shadow: 0px 0px 0px 1px #fff; }

/*Subscription CSS Start*/
.subscription-block {
  background-color: #233d7b;
  padding: 40px 0;
  float: left;
  width: 100%; }

.subscription-block h4 {
  color: #fff;
  font-size: 18px; }

.subscription-block .form-control {
  padding-left: 20px;
  font-size: 14px;
  float: left;
  width: 63%;
  margin-right: 10px; }

.subscription-block .btn {
  min-width: 35%; }

.subscription-block .container-small {
  max-width: 900px; }

/*Footer CSS start*/
.footer-block {
  padding: 40px 0 0 0; }

.footer-logo p {
  font-size: 15px;
  color: #777;
  line-height: 30px; }

.footer-title {
  color: #233d7b;
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 30px; }

.footer-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #00a8a0;
  width: 30px;
  height: 3px; }

.footer-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  background-color: #00a8a0;
  width: 80px;
  height: 1px; }

.footer-links ul {
  text-align: left; }

.footer-links li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px; }

.footer-links li button.link {
  border: 1px solid #d7d7d7;
  padding: 1px 11px;
  color: #333333;
  display: block;
  font-size: 14px; }

.footer-links li button.link:hover {
  border: 1px solid #00a8a0;
  text-decoration: none; }

.footer-keepin-touch li {
  float: left;
  margin-bottom: 6px;
  list-style: none; }

.footer-keepin-touch li a {
  color: #333333;
  font-size: 16px;
  text-decoration: none;
  padding-left: 10px; }

.footer-keepin-touch li a:hover, .footer-keepin-touch li button.link:hover {
  color: #233d7b; }

.footer-social ul {
  margin: 0;
  padding: 0; }

.footer-social ul li {
  list-style: none;
  margin-right: 5px;
  display: inline-block;
  transition: all .3s;
  border-radius: 5px; }

.footer-social ul li a {
  color: #fff;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  display: block; }

.footer-social ul li:hover {
  opacity: 0.8; }

.footer-social ul li.facebook-icon {
  background-color: #4f76bb; }

.footer-social ul li.linkedin-icon {
  background-color: #1387c8; }

.footer-social ul li.twitter-icon {
  background-color: #34c4f3; }

.footer-social ul li.insta-icon {
  background: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF); }

.footer-bottom {
  background-color: #f2f2f2;
  padding: 15px 0;
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  text-align: center; }

.sticky-footer {
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  float: left;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; }

.sticky-footer ul {
  margin: 0;
  padding: 0;
  text-align: center; }

.sticky-footer ul li {
  display: inline-block;
  list-style: none;
  text-align: center;
  width: 20%;
  border-right: 1px solid #19b1a9; }

.sticky-footer ul li:last-child {
  border-right: 0; }

.sticky-footer ul li button.link {
  color: #fff;
  text-decoration: none;
  padding: 6px 0;
  display: block;
  margin: 0 auto; }

.sticky-footer ul li:hover {
  background-color: #00a8a0; }

.sticky-footer .category-pic {
  margin-bottom: 4px; }

.sticky-footer .category-item {
  word-break: break-all;
  font-size: 14px;
  color: #fff; }

.sticky-footer ul li button.link .dropdown-menu a {
  color: #000 !important;
  border-bottom: 1px solid #e9ebf2 !important; }

.sticky-footer ul li button.link .dropdown-menu {
  border: 0;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  padding: 5px 15px;
  min-width: 200px; }

.sticky-footer ul li.dropdown li {
  width: 100%;
  border-bottom: 1px solid #e5f4f3;
  border-right: 0; }

.sticky-footer ul li .dropdown-toggle::after {
  display: none; }

.sticky-footer ul li .dropdown-item {
  text-align: left;
  padding-left: 15px; }

.sticky-footer ul li .dropdown-item span {
  padding-right: 5px; }

.howit-works-desktop {
  display: block; }

.howit-works-slider {
  display: none; }

.forms .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5); }

.forms select.form-control {
  color: #000;
  position: relative;
  -webkit-appearance: none;
  line-height: 20px; }

.select-box {
  position: relative; }

.select-box .form-control {
  width: 100% !important; }

.select-box:after {
  font-family: FontAwesome;
  content: '\f107';
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #b4b4b4;
  pointer-events: none; }

.select-box .p-dropdown-trigger {
  display: none !important; }

.select-box .p-inputtext {
  background: none !important; }

.forms .form-control {
  font-size: 16px;
  color: rgba(4, 2, 3, 0.8);
  height: 40px;
  line-height: 26px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px solid #233d7b; }

.forms textarea.form-control-textarea {
  border-radius: 10px;
  height: 120px; }

.forms {
  max-width: 100%;
  background-color: #fff;
  border-radius: 6px; }

.forms .modal-body .btn {
  width: 100%;
  text-transform: none; }

.forms .modal-body {
  padding: 0px 30px 0px 40px; }

.forms .modal-body p {
  font-size: 14px;
  text-align: center;
  margin: 0 auto 30px; }

.forms .modal-body strong {
  font-size: 18px;
  text-align: left; }

.forms .modal-body p button.link {
  color: #000;
  text-decoration: underline; }

.forms .modal-body p button.link:hover {
  color: #233d7b; }

.forms .modal-title {
  font-size: 28px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  line-height: 0.54;
  text-align: left;
  color: #233d7b;
  text-transform: none; }

.forms .close {
  font-size: 40px;
  line-height: 0.56;
  color: #233d7b;
  opacity: 9; }

.forms .modal-header {
  padding: 40px 30px 40px 40px;
  border: none; }

.forms .modal-footer {
  background-color: #f5f5f5;
  border-top: 0;
  padding: 20px 80px;
  margin-top: 30px;
  display: block;
  width: 100%;
  float: left; }

.forms .modal-footer p {
  text-align: left;
  float: left;
  padding: 0;
  line-height: 40px;
  margin: 0; }

.forms .modal-footer .btn {
  float: right;
  text-transform: none; }

.forms label {
  font-size: 16px;
  color: #000; }

.forms .with-signup {
  color: #00a8a0;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin: 20px 0;
  font-weight: 700;
  background-color: #fff;
  display: block; }

.forms .with-signup:after {
  position: absolute;
  content: "";
  left: 140px;
  top: 10px;
  height: 1px;
  background: #c9c9c9;
  z-index: 1;
  width: 50px; }

.forms .with-signup:before {
  position: absolute;
  content: "";
  right: 140px;
  top: 10px;
  height: 1px;
  background: #c9c9c9;
  z-index: 1;
  width: 50px; }

.forms.modal-dialog {
  width: 583px; }

.react-phone-number-input__country-select-arrow, .react-phone-number-input__country-select {
  display: none !important; }

.react-phone-number-input__icon {
  height: 18px !important; }

.required {
  color: #cc0000;
  font-size: 16px; }

.Social-icon {
  text-align: center; }

.Social-icon ul {
  margin: 0;
  padding: 0; }

.Social-icon ul li {
  display: inline-block; }

.Social-icon ul li a {
  color: #fff;
  display: block;
  border-radius: 22px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-decoration: none; }

.Social-icon ul i, .Social-icon .linkedin-sdk:before {
  padding-right: 20px;
  margin-right: 0 !important; }

.Social-icon ul .google-clr {
  margin: 12px; }

.Social-icon ul li.facebook-clr a {
  background-color: #3b5999; }

.Social-icon ul button.link.google-clr {
  background-color: #dd5144; }

.Social-icon ul li.linkedin-clr a {
  background-color: #007cb8; }

.modal .Social-icon .btn {
  width: auto; }

.modal .Social-icon .linkedin-sdk {
  font-family: 'Lato', sans-serif !important; }

.modal .Social-icon .linkedin-sdk:before {
  font-family: FontAwesome  !important; }

.modal .Social-icon .Facebook {
  background: #38589a; }

.modal .Social-icon .linkedin-sdk {
  background: #007cb7; }

.modal .Social-icon .Facebook:hover {
  background: #2b4780; }

.modal .Social-icon ul button.link.google-clr:hover {
  background: #c34438; }

.modal .Social-icon .linkedin-sdk:hover {
  background: #046694; }

/*CUstom Checkbox Label*/
.checkbox-custom {
  opacity: 0;
  position: absolute; }

.checkbox-custom, .checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0; }

.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjgwOTcyMDJCMkU4NTExRTk4NDYxRjM2RDUwOUU4QjEyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjgwOTcyMDJDMkU4NTExRTk4NDYxRjM2RDUwOUU4QjEyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODA5NzIwMjkyRTg1MTFFOTg0NjFGMzZENTA5RThCMTIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODA5NzIwMkEyRTg1MTFFOTg0NjFGMzZENTA5RThCMTIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7JXZhoAAAA6UlEQVR42qyUvQrCMBSF00I3oX2Huvgqpbuu4l4EO/UVdBBnFUfdtc/i4Ka7CE4WrOfILZRQsT+58EET7vlIm6SW2u9URc3ABPSBI3MZuIAtWOgBWxsH4C6Ng5JEyTPn5tIT/BJF4Ag89b886Y10Ee1LYKn6ZUkmKETk0FBSljFrUxKDnmpfzMYUjVX3GlPkGxD5trbFbcuhKDcg+u5YZsCTUXQzILpStDEgWlu4tJQ9OpylJ3ApeYNhy4/OzIiO4q6lYNpQlkvmpN/+FQhlqXVeJ5RM5f+IK3NBAs7a0XjJXCI9aTn4EWAALpsszLLAVe8AAAAASUVORK5CYII=);
  background-repeat: no-repeat; }

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg1MEQ4RUE1MkU4NTExRTk4MEZCOEQxQTBDQkM5NjdGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg1MEQ4RUE2MkU4NTExRTk4MEZCOEQxQTBDQkM5NjdGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODUwRDhFQTMyRTg1MTFFOTgwRkI4RDFBMENCQzk2N0YiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODUwRDhFQTQyRTg1MTFFOTgwRkI4RDFBMENCQzk2N0YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6x6Nx/AAABtElEQVR42mJkWLGAAQtwAuJwKC0HFXsExPuAeBUQ70XXwILG1wDi6UDsgMVwFShOA+IDQJwJxDdgkkxICu2B+BQOQ9CBA1StPbpBIJdsBGJeBuIBL1SPBswgRiCeD8T8DKQDfqheRpBBzkBsQYruXFVNhhteATAuSK8zKLDDiDUA5PRmXUOGLBUNBr/DKBEXxgKNYoKAmZGRYaaJJYOHpDSD3b7tDFc+fkBJLiCDZAkZwsnMzLDC0p5BjZePwXrvdoaHX7+gK5EFhdFPGA/k72QlVRQVgmxsDLsc3BjEOTgZbLAbAo/+ZzBO9tmTDH0GpgzVWnpgvjQnF8MhJ0+Gb3/+MLgc2Mnw9tdPXI5+wgJNWOog3t6Xzxmc9u9k2GrnzKDMw8vgJCbBcPTNK4bEU0cZfv37h8/3R0EuWooscvb9W7AXrEXEGNY+ecQQc+IwIUNAYAkjMNOCYvUYqWkJCZwAYiuQi/4DcSIQfyTDEJCeJJAZsLwGysX+QPyZBEM+Q/VcR8/9B4HYDOpUYrxjBtWDtTwCucwKiF2BOASaD0EJ9hcQPwbiw0C8Boh3Q4MEDgACDABQfW28nx5e7wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat; }

.checkbox-block {
  width: 100%;
  float: left;
  margin-top: 20px; }

.checkbox-block .custom-checkbox {
  float: left; }

.checkbox-block .forgot-pass {
  float: right;
  color: #233d7b;
  border-bottom: 1px solid;
  text-decoration: none; }

.custom-radiobox {
  float: left;
  margin-right: 40px; }

.pause-icon {
  box-shadow: #f1f3f4 9px 0px 0px -2px inset !important;
  width: 12px !important;
  background-color: #535c64 !important;
  border-left: 5px solid #535c64 !important; }

.toggle-play-button {
  padding-top: 0px !important; }

.checkbox-block .p-radiobutton .p-radiobutton-box {
  height: 16px !important;
  width: 16px !important;
  border: 1px solid #233d7b !important; }

.checkbox-block .p-radiobutton {
  margin-right: 6px !important; }

.checkbox-block .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover, .checkbox-block .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #233d7b !important;
  background-color: #fff  !important;
  color: #ffffff; }

.checkbox-block .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  height: 8px !important;
  width: 8px !important;
  background-color: #233d7b !important;
  margin-left: -4px !important;
  margin-top: -4px !important; }

.rounded-circle {
  overflow: hidden;
  height: 42px;
  width: 42px; }

.rounded-circle img {
  height: 100%;
  vertical-align: inherit; }

.profile-box {
  text-align: center; }

.profile-box .profile-pic {
  border-radius: 50%;
  margin-bottom: 30px;
  height: 170px;
  width: 170px;
  margin: 0 auto;
  margin-bottom: 30px;
  background-color: #000;
  position: relative; }

.profile-box .profile-pic .rounded-circle {
  overflow: hidden;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.3);
  height: 170px;
  width: 170px; }

.profile-box .profile-pic .rounded-circle img {
  height: 100%; }

.profile-box .profile-pic > img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.3s;
  cursor: pointer; }

.profile-box .profile-pic span .fa {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 170px;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  opacity: 0;
  transition: .3s ease;
  background-color: #000;
  font-size: 32px;
  color: #fff; }

.profile-box .profile-pic:hover span .fa {
  display: block;
  opacity: 0.7; }

.profile-box .profile-name {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: #233d7b;
  text-transform: uppercase; }

.profile-box .profile-name span {
  font-size: 15px;
  font-weight: normal;
  color: #333333;
  text-transform: none;
  display: block;
  word-break: break-all; }

.inner-section {
  margin: 40px 0;
  float: left;
  width: 100%; }

.left-sidebar, .left-sidebar-box {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  background-color: #ffffff;
  padding: 40px 20px;
  max-height: 570px;
  margin-bottom: 20px; }

.left-sidebar-box {
  max-height: 580px; }

.right-sidebar {
  padding-left: 70px; }

.right-sidebar .btn, .right-sidebarblock .btn {
  text-transform: none; }

.right-sidebar .forms {
  width: 100%; }

.right-sidebarblock {
  padding-left: 70px; }

.account-detail ul {
  margin: 0;
  padding: 0; }

.account-detail ul li {
  padding: 10px 0  10px 30px;
  list-style: none;
  border-bottom: 1px solid #e9ebf2;
  position: relative; }

.account-detail ul li:last-child {
  border-bottom: 0; }

.account-detail ul li button.link {
  font-size: 16px;
  color: #000;
  text-decoration: none; }

.account-detail ul li:after {
  height: 20px;
  width: 20px;
  position: absolute;
  content: "";
  left: 0;
  top: 14px;
  background-image: url(/static/media/sprite-img.695d09e7.png);
  background-repeat: no-repeat; }

.account-detail:before {
  content: '';
  position: absolute;
  top: -8px;
  right: 25%;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

.left-sidebar .account-detail:before {
  content: unset !important; }

.account-detail {
  position: relative;
  margin-top: 20px; }

.account-detail ul li.profile-icon:after {
  background-position: -8px -164px; }

.account-detail ul li.business-icon:after {
  background-position: -7px -194px; }

.account-detail ul li.password-icon:after {
  background-position: -8px -225px; }

.account-detail ul li.notification-icon:after {
  background-position: -8px -255px; }

.account-detail ul li.reviews-icon:after {
  background-position: -8px -285px; }

.account-detail ul li.logout-icon:after {
  background-position: -8px -315px; }

.account-detail ul li.patronized-icon:after {
  background-position: -8px -343px; }

.account-detail ul li.filter-icon:after {
  background-position: -74px -165px; }

.account-detail ul li.cate-icon:after {
  background-position: -74px -194px; }

.account-detail ul li.opennow-icon:after {
  background-position: -74px -225px; }

.account-detail ul li.location-icon:after {
  background-position: -74px -255px; }

.account-detail ul li.pricerange-icon:after {
  background-position: -74px -286px; }

.account-detail ul li.share-file-icon:after {
  background-position: -74px -324px; }

.account-detail ul li.share-video-icon:after {
  background-position: -74px -364px; }

.account-detail ul li.share-image-icon:after {
  background-position: -74px -407px; }

.account-detail ul li.share-location-icon:after {
  background-position: -74px -448px; }

.account-detail ul li.user-report-icon:after {
  background-position: -8px -375px; }

.account-detail ul li:hover.share-file-icon:after {
  background-position: -105px -324px; }

.account-detail ul li:hover.share-video-icon:after {
  background-position: -105px -364px; }

.account-detail ul li:hover.share-image-icon:after {
  background-position: -105px -407px; }

.account-detail ul li:hover.share-location-icon:after {
  background-position: -105px -448px; }

.account-detail ul li:hover.user-report-icon:after {
  background-position: -39px -375px; }

.account-detail ul li:hover.filter-icon:after {
  background-position: -107px -165px; }

.account-detail ul li:hover.cate-icon:after {
  background-position: -107px -194px; }

.account-detail ul li:hover.opennow-icon:after {
  background-position: -107px -225px; }

.account-detail ul li:hover.location-icon:after {
  background-position: -107px -255px; }

.account-detail ul li:hover.pricerange-icon:after {
  background-position: -107px -286px; }

.account-detail ul li:hover.profile-icon:after {
  background-position: -39px -164px; }

.account-detail ul li:hover.business-icon:after {
  background-position: -38px -194px; }

.account-detail ul li:hover.password-icon:after {
  background-position: -39px -225px; }

.account-detail ul li:hover.notification-icon:after {
  background-position: -39px -255px; }

.account-detail ul li:hover.reviews-icon:after {
  background-position: -39px -285px; }

.account-detail ul li:hover.logout-icon:after {
  background-position: -39px -315px; }

.account-detail ul li:hover.patronized-icon:after {
  background-position: -39px -343px; }

.account-detail ul li:hover button.link {
  color: #00a8a0; }

.account-detail ul li.active button.link {
  color: #00a8a0; }

.account-detail ul li.active.business-icon:after {
  background-position: -38px -194px; }

.account-detail ul li.active.profile-icon:after {
  background-position: -39px -164px; }

.account-detail ul li.active.password-icon:after {
  background-position: -39px -225px; }

.account-detail ul li.active.notification-icon:after {
  background-position: -39px -255px; }

.account-detail ul li.active.reviews-icon:after {
  background-position: -39px -285px; }

.account-detail ul li.active.logout-icon:after {
  background-position: -39px -315px; }

.account-detail ul li.active.patronized-icon:after {
  background-position: -39px -343px; }

.inner-title {
  color: #233d7b;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 30px; }

.sub-title {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px; }

.inner-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #233d7b;
  width: 30px;
  height: 3px; }

.inner-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  background-color: #233d7b;
  width: 80px;
  height: 1px; }

.inner-title-2 {
  color: #233d7b;
  font-size: 22px;
  font-weight: 700;
  text-transform: none; }

.inner-footer {
  position: relative;
  margin-top: 40px; }

.inner-footer:after {
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 5px;
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  width: 100%; }

/*Business Section*/
.add-pic-block {
  background-image: linear-gradient(to right, #00a8a0, #233d7b);
  height: 154px;
  width: 154px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  display: inline-block; }

.add-pic-block:after {
  position: absolute;
  content: "";
  left: -7px;
  top: -7px;
  height: 168px;
  width: 168px;
  border: 1px solid #233d7b;
  border-radius: 50%; }

.added-my-business {
  text-align: center;
  margin: 40px 0; }

.map {
  border-radius: 10px;
  height: 250px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); }

.added-my-business h4 {
  font-size: 26px;
  color: #233d7b;
  text-transform: none;
  font-weight: normal; }

.added-my-business h4 span {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #000;
  font-size: 18px;
  display: block; }

.phone-number-change .added-my-business {
  margin: 50px 0 0px 0; }

.phone-number-change .mybusiness-detail {
  margin-top: 30px;
  margin-bottom: 20px; }

.phone-number-change.forms.modal-dialog {
  width: 453px; }

.phone-number-change .btn-center {
  text-align: center; }

.phone-number-change .btn {
  float: none !important;
  text-align: center;
  margin: 0 auto; }

.phone-number-change .mybusiness-detail h4 {
  font-size: 20px; }

.mybusiness-detail {
  margin-top: 30px;
  margin-bottom: 30px; }

.mybusiness-detail h4 {
  color: #00a8a0; }

.mybusiness-detail h4, .mybusiness-detail h5 {
  text-transform: none;
  font-weight: 700; }

.business-brand {
  float: left;
  margin-right: 18px;
  width: 140px;
  height: 120px;
  text-align: center;
  overflow: hidden; }

.business-brand img {
  transform: translateY(-50%);
  top: 50%;
  position: relative;
  width: 100%; }

.business-brand-detail {
  float: left;
  width: 70%; }

.business-brand-detail h3 {
  font-size: 17px;
  line-height: 30px;
  text-transform: none; }

.business-brand-detail h3 strong {
  float: left;
  width: 100%; }

.business-brand-detail h3 span {
  font-size: 16px;
  display: inline-block;
  font-weight: normal;
  line-height: 26px; }

.business-brand-detail li {
  list-style: none;
  float: left;
  font-size: 13px;
  line-height: 30px;
  margin-right: 50px; }

.business-brand-detail li:last-child {
  margin-right: 0; }

span.brand-icon, .business-brand-detail ul li span {
  background: #00a8a0;
  vertical-align: middle;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  line-height: 25px;
  display: inline-block;
  text-align: center;
  margin-right: 5px; }

.moreinfo span.brand-icon {
  cursor: pointer; }

.moreinfo .date span {
  cursor: pointer; }

.business-discription {
  float: left;
  font-size: 16px;
  padding-top: 15px;
  width: 100%;
  word-break: break-all; }

.business-discription .morecontent span {
  display: none; }

.business-discription .morelink {
  display: inline-block;
  color: #233d7b; }

.serv-business-discription {
  float: left;
  font-size: 16px;
  padding-top: 10px;
  width: 100%; }

.serv-business-discription .business-discription .morecontent span {
  display: none; }

.serv-business-discription .business-discription .morelink {
  display: inline-block;
  color: #233d7b; }

.business-listing {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  float: left;
  width: 100%;
  padding: 20px;
  position: relative;
  margin-top: 30px; }

.business-listing .edit-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  height: 27px;
  width: 27px;
  background-color: #f2f2f2;
  border-radius: 50%;
  text-align: center; }

.business-listing .edit-btn:hover {
  background-color: #ddd; }

.business-listing .edit-btn a {
  cursor: pointer;
  height: 27px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: block;
  width: 27px; }

.business-listing .edit-btn a:hover {
  background-color: #ddd; }

.business-listing.business-render .edit-btn {
  background: none;
  border-radius: 0;
  width: auto;
  height: auto;
  cursor: auto; }

/*Business History CSS*/
.review-listing.business-render:first-child {
  margin-top: 40px; }

.review-listing.business-render {
  margin-top: 20px;
  padding-bottom: 0;
  padding-top: 0; }

.business-listing.business-render ul li {
  margin-right: 0; }

.review-listing .review-listing-under, .review-listing .business-history-date {
  padding-top: 20px; }

.review-service-list ul {
  text-align: center;
  margin-top: 30px; }

.review-service-list ul li {
  display: inline-block;
  width: 30%;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  margin: 10px;
  padding: 15px 10px;
  text-align: center; }

.review-service-list .rounded-circle {
  margin: 0 auto; }

.review-service-list .his-servicename {
  float: none;
  max-width: inherit;
  padding-top: 10px; }

.review-service-list .link {
  padding: 0; }

.business-render .review-detail ul li {
  width: 50%; }

.business-render .date {
  font-size: 14px; }

.date .btn {
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px !important;
  min-width: 120px;
  margin-left: 20px;
  text-transform: unset; }

.pagination-block {
  margin-top: 40px;
  float: none;
  width: 100%;
  margin: 0 auto;
  text-align: center; }

.pagination-block li.active a {
  background-color: #233d7b !important;
  border-color: #233d7b !important;
  color: #fff !important; }

.pagination-block li a {
  color: #233d7b !important;
  cursor: pointer !important; }

/*Review Section*/
.review-listing {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  float: left;
  width: 100%;
  padding: 20px;
  position: relative;
  margin-top: 40px; }

.review-detail {
  float: left;
  width: 100%; }

.review-detail ul {
  text-align: center; }

.review-detail ul li {
  list-style: none;
  float: left;
  width: auto;
  text-align: left;
  line-height: 30px; }

.review-detail ul li:last-child {
  text-align: right;
  padding-left: 30px;
  float: right; }

.review-detail ul li:first-child {
  width: 45%;
  float: left; }

.review-discription {
  background-color: #f2f2f2;
  padding: 8px;
  display: inline-block;
  margin-top: 10px;
  border-radius: 6px;
  position: relative; }

.review-discription:after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f2f2f2;
  position: absolute;
  left: 5px;
  top: -8px;
  content: ""; }

.review-detail ul li .fa {
  color: #ff7700; }

.review-detail ul li span {
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
  margin-left: 6px; }

.review-detail ul li span img {
  vertical-align: middle; }

.reviewer-name {
  font-size: 15px !important;
  line-height: 19px;
  color: #333;
  font-weight: 700;
  padding-left: 5px; }

.review-rating {
  margin: 15px 0 20px 0;
  float: left;
  width: 100%;
  line-height: 28px; }

.review-rating > span {
  font-size: 16px;
  float: left;
  padding-right: 20px; }

.review-rating strong {
  font-size: 21px;
  float: left;
  padding-left: 5px; }

.review-rating i {
  color: #ff7700;
  font-size: 28px;
  float: left;
  margin-right: 6px; }

.review-rating .review-point {
  float: left; }

.review-listing .rounded-circle {
  overflow: hidden;
  height: 34px;
  width: 34px;
  float: left; }

.review-listing .rounded-circle img {
  height: 100%; }

.review-detail .business-history-date {
  float: left; }

.review-detail .times-hired {
  float: right;
  text-align: center;
  width: 140px;
  padding-bottom: 10px; }

.review-detail .times-hired a {
  margin-left: 0; }

.his-servicename {
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; }

.his-servicename > span {
  padding-right: 10px; }

.mystyle {
  width: 100%;
  padding: 25px;
  background-color: coral;
  color: white;
  font-size: 25px;
  box-sizing: border-box; }

/*404 Section*/
.error-block p {
  font-size: 20px;
  line-height: 30px;
  padding: 30px 0; }

ul.bullet-list {
  margin-bottom: 20px; }

ul.bullet-list li {
  list-style: none;
  text-decoration: none;
  position: relative;
  padding-left: 20px;
  line-height: 36px; }

ul.bullet-list li:after {
  position: absolute;
  content: "";
  left: 0;
  top: 16px;
  height: 6px;
  width: 6px;
  background-color: #233d7b;
  border-radius: 50%; }

.faq-banner {
  background-image: url(/static/media/faq-banner.80e1862a.jpg); }

.about-banner {
  background-image: url(/static/media/about-banner.e61bd612.jpg); }

.terms-condition-banner {
  background-image: url(/static/media/terms-condition-banner.bae2c9ad.jpg); }

.blog-banner {
  background-image: url(/static/media/blog-banner.21219d8e.jpg); }

.shafty-tips-banner {
  background-image: url(/static/media/shafty-tips-banner.c3c5a9be.jpg); }

.privacy-policy {
  background-image: url(/static/media/privacy-policy.fa63404d.jpg); }

.contact-banner {
  background-image: url(/static/media/contact-banner.8fe741c8.jpg); }

.inner-banner-section {
  position: relative;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat; }

.inner-banner-section h1 {
  position: absolute;
  left: 0;
  bottom: 20px;
  right: 0;
  text-align: center;
  color: #ffffff; }

.inner-banner-section .home-form-block {
  display: none; }

/*Blog Post Section*/
.blog-section .post-meta {
  margin-bottom: 15px;
  color: #000;
  font-size: 14px;
  text-transform: uppercase; }

.blog-section .post-meta .post-cat > a {
  color: #233d7b;
  font-weight: 600; }

.blog-section .post-meta .meta-sep:before {
  content: "/";
  padding: 0 6px; }

.blog-section .post-counters {
  float: right; }

.blog-section .card-footer a {
  color: #00a8a0;
  font-size: 14px;
  text-decoration: none; }

.blog-section .card-footer a:hover {
  color: #233d7b; }

.blog-section .card-footer .number {
  padding-left: 8px; }

.blog-section .card-deck .card {
  margin-bottom: 30px; }

/*About Us*/
.half-pic-block {
  background-image: url(/static/media/about-pic.cfbc839c.jpg);
  height: 400px;
  position: relative;
  width: 100%;
  background-size: cover; }

.right-shadow:after {
  position: absolute;
  content: "";
  right: -10px;
  top: 10px;
  background: #ddd;
  width: 100%;
  height: 100%;
  z-index: -1; }

.left-shadow:after {
  position: absolute;
  content: "";
  left: -10px;
  top: 10px;
  background: #ddd;
  width: 100%;
  height: 100%;
  z-index: -1; }

.tab-block .card-header a {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  display: block; }

.tab-block .card-header a i {
  float: right;
  font-weight: 700;
  line-height: 28px; }

.tab-block .card-header a:not(.collapsed) .rotate-icon {
  transform: rotate(90deg); }

.tab-block .card-header {
  background: #233d7b; }

.contact-detail-pic {
  margin-bottom: 20px;
  background-image: url(/static/media/contact-pic.663d690c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 380px; }

/*LiveChat CSS*/
.lc-1lh2dzt {
  left: 0 !important;
  right: auto !important;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  width: 350px !important;
  height: 355px !important;
  top: 0; }

.lc-155taeu {
  background: #233d7b !important;
  padding: 15px 20px !important; }

.lc-16tc1wt {
  border-top: 1px solid #eee !important; }

.lc-1ru26ch {
  font-size: 20px !important; }

.lc-1lh2dzt > div {
  max-width: 100% !important; }

.lc-1aes1f2 * {
  fill: #233d7b !important; }

.lc-ahcp00 > div {
  background: #233d7b !important; }

.lc-1lh2dzt.ep7mz240 > div {
  background: #fff !important; }

.lc-2ntbdr {
  float: left;
  width: 100%;
  padding: 20px 20px !important;
  margin-bottom: 0;
  border: 0 !important;
  position: absolute;
  bottom: 0; }

.rce-container-citem {
  float: left;
  width: 100%;
  padding: 20px 20px 10px 20px; }

.rce-citem-avatar {
  float: left;
  width: 100px; }

.rce-avatar-container.circle.large {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; }

img.rce-avatar {
  width: 100%;
  height: 100%; }

.rce-citem-body {
  float: left;
  margin-left: 20px; }

.rce-citem-body--top-title {
  font-size: 20px; }

.lc-ggtqtw {
  border: 1px solid #233d7b !important;
  border-radius: 50% !important;
  text-align: center !important;
  background-color: #fff !important;
  text-transform: uppercase !important;
  width: 40px !important;
  height: 40px !important;
  overflow: hidden; }

.lc-rchvvg.ek650k30 {
  width: 87%;
  float: right; }

.lc-1f0e56z {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  height: auto  !important;
  background-color: transparent !important; }

.lc-bgzoqr {
  margin-bottom: 0 !important; }

.lc-1lt0t9n {
  font-weight: 700;
  font-size: 12px !important; }

.test {
  overflow-y: scroll !important;
  height: 210px !important; }

.lc-1lh2dzt .btnBack {
  line-height: 30px !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  background-color: #00a8a0 !important;
  border: 1px solid #00a8a0 !important;
  color: #fff !important;
  -webkit-border: 1px solid #00a8a0 !important;
  -moz-border: 1px solid #00a8a0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-width: 50px !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0; }

.lc-1lh2dzt .btnBack:hover {
  background-color: #233d7b !important; }

body.modal-open .lc-1k3cgzy {
  right: 33px; }

.error-msg {
  color: #cc0000; }

.user-profile-update .error-msg {
  color: #cc0000;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 15px; }

.lc-1k3cgzy {
  right: auto !important;
  left: auto !important;
  position: unset !important; }

.lc-103t8u6 {
  font-size: 16px !important;
  font-weight: 700 !important; }

.lc-1l6cxzg {
  font-size: 13px !important; }

.lc-k64nd1.egyled90 {
  max-width: 100% !important; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-header .navbar-collapse {
    flex-grow: inherit; } }

/*Loader CSS*/
#small-loader {
  position: relative;
  background: transparent;
  width: 100%; }

#small-loader .loader-gif, #loader-overlay-saperate .loader-gif {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  background-image: url(/static/media/loader.a65e7c85.gif);
  background-repeat: no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0; }

.loader-gif {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  position: absolute;
  z-index: 100;
  background-image: url(/static/media/loader.a65e7c85.gif);
  background-repeat: no-repeat;
  background-size: 100%;
  left: 0;
  top: 45%;
  right: 0; }

#loader-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 50;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0; }

#loader-overlay-saperate {
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  z-index: 50;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0; }

body .p-messages.p-messages-warn {
  width: auto;
  position: absolute;
  top: 43px;
  z-index: 1;
  background-image: linear-gradient(to right, #00a8a0, #233d7b) !important;
  color: #fff !important;
  border: 1px solid #f7f6f6 !important; }

body .p-messages .p-messages-icon {
  color: #fff !important; }

body .p-messages .p-messages-close {
  background-color: #00a8a0 !important;
  color: #fff !important; }

body .p-messages .p-messages-wrapper {
  text-align: left !important; }

/*Search Result Page CSS*/
.search-count {
  color: #233d7b;
  float: left;
  width: 100%;
  margin-bottom: 30px; }

.search-count .search-countleft {
  float: left; }

.search-count .search-countright {
  float: right; }

.searchresult-categoryblock {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px; }

.search-icon-box .form-control {
  padding-left: 45px;
  padding-right: 40px; }

.search-icon-box {
  position: relative; }

.search-icon-box .locate-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  color: #333;
  cursor: pointer; }

.search-icon-box:after {
  position: absolute;
  content: '\F002';
  font-family: 'FontAwesome';
  font-size: 18px;
  left: 20px;
  top: 9px;
  color: #00a8a0; }

.categoryblock-img {
  position: relative;
  width: 30%;
  float: left;
  height: 180px; }

.categoryblock-img:after {
  position: absolute;
  left: 10px;
  content: "";
  top: 10px;
  bottom: 10px;
  right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5); }

.searchcat-title-box ul li {
  list-style: none;
  border-bottom: #ebebeb 1px solid;
  padding-bottom: 8px;
  margin-bottom: 8px;
  float: left;
  width: 100%; }

.searchcat-title-box ul li:last-child {
  border: none; }

.categoryservice-block {
  width: 62%;
  float: left;
  margin-left: 30px;
  padding: 15px 0 4px 0; }

.serv-name {
  font-size: 18px;
  line-height: 30px; }

.serv-name > span {
  font-size: 14px;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 460px; }

.search-review {
  float: right;
  line-height: 20px; }

.search-review span {
  margin-left: 3px;
  color: #000;
  font-size: 14px; }

.search-review .fa {
  color: #ff7700; }

.address-timeblock {
  text-align: center;
  word-break: break-all; }

.address-timeblock strong {
  font-weight: normal;
  font-size: 15px; }

.address-timeblock .locationname {
  float: left;
  width: 73%;
  text-align: left; }

.address-timeblock .locationname img {
  margin-right: 5px; }

.address-timeblock .timename {
  color: #209e19; }

.address-timeblock .phonename {
  font-weight: 300;
  float: right; }

.moreinfo {
  text-align: center; }

.moreinfo .date {
  font-size: 14px;
  display: inline-block; }

.search-result-left {
  display: block;
  padding-top: 0;
  padding-bottom: 20px;
  max-height: max-content;
  padding-left: 10px;
  padding-right: 10px; }

.account-detail .search-filtermenu ul li {
  padding-left: 0; }

.account-detail .search-filtermenu ul li a {
  font-size: 16px; }

.filter-icon span {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 20px;
  color: #000;
  margin-top: 4px; }

@keyframes minus {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.filter-icon [aria-expanded="false"] > .expanded, .filter-icon [aria-expanded="true"] > .collapsed {
  display: none; }

body .p-growl .p-growl-item-container.p-growl-message-error {
  background-color: #de4b39 !important; }

.p-growl.p-growl-topright {
  opacity: 9 !important;
  z-index: 99999 !important;
  top: 130px !important; }

span.p-growl-image.pi.pi-times, span.p-growl-image.pi.pi-check {
  display: none !important; }

.p-growl-message {
  margin: 0 !important;
  padding: 0 10px; }

span.p-growl-icon-close-icon.pi.pi-times, .p-growl-message p, .p-growl-title {
  color: #fff !important; }

body .p-growl .p-growl-item-container.p-growl-message-success {
  background-color: #5eb762 !important; }

.search-result-left .p-dropdown label.p-dropdown-label {
  line-height: 26px;
  padding: 0;
  width: 87%; }

.search-result-left .p-dropdown-panel .p-dropdown-item {
  line-height: 24px;
  border-bottom: 1px solid #e9ebf2 !important;
  text-transform: uppercase; }

.search-result-left .pi {
  font-size: 12px; }

.search-result-left .p-dropdown {
  border: 0 !important;
  height: auto;
  width: 100% !important; }

.search-result-left .p-dropdown.form-control:focus {
  border-color: none;
  box-shadow: none; }

.search-result-left .p-dropdown .p-dropdown-panel {
  z-index: 1 !important; }

.media-placeholder {
  width: 75%;
  border: 2px #e1e1e1 dashed;
  text-align: center;
  padding: 30px 0;
  margin: 0 auto;
  cursor: pointer; }

.user-sidebar-img {
  width: 100%; }

.add-business-upload-block {
  width: 100%  !important;
  border: none !important; }

.upload-businessimg p {
  font-size: 18px;
  margin-bottom: 40px; }

.upload-businessimg:focus {
  outline: none;
  border: none; }

.uploaded-img {
  margin-top: 40px;
  width: 100%;
  overflow-y: auto;
  height: 130px; }

.img-wrap {
  position: relative;
  display: inline-block; }

.img-wrap .close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 0;
  color: #000;
  cursor: pointer;
  border: 1px solid #000;
  opacity: .5;
  text-align: center;
  border-radius: 50%;
  text-shadow: none;
  background: #fff;
  height: 20px;
  width: 20px;
  line-height: 18px;
  font-size: 17px; }

div#react-autowhatever-1, #react-autowhatever-source {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  list-style-type: none;
  width: 100%;
  z-index: 99;
  overflow: auto;
  max-height: 320px; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
  z-index: 99; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.search-block input, .sticky-search-block input, .react-autosuggest__input {
  font-size: 18px;
  color: rgba(4, 2, 3, 0.8);
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 0;
  -webkit-border: 0;
  -moz-border: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  padding-left: 60px; }

.result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 80%; }

.p-checkbox {
  margin-right: 10px !important; }

.p-checkbox .p-checkbox-box {
  background: #00a8a0 !important;
  border: 0 !important;
  border-radius: 50% !important; }

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 14px !important;
  line-height: 20px !important; }

.footer-search-component .react-autosuggest__input {
  font-size: 16px;
  color: rgba(4, 2, 3, 0.8);
  height: 40px;
  line-height: 28px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px solid #233d7b;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 0;
  width: 100%; }

/*Chat Screen Design New*/
.back_btn_mob {
  display: none; }

.back_btn_mob button {
  min-width: auto; }

.leftside-desktop {
  display: block; }

.forgotepassword-block .forms .tabbing-block .nav-tabs .nav-link {
  line-height: 40px; }

/*Pagination Block Start*/
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination > li {
  display: inline; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default; }

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.pagination > li.disabled a {
  background-color: #f6f6f6 !important;
  margin-right: 2px; }

/*Search Bar*/
.searchbar-block {
  background: #eaecf2;
  float: left;
  width: 100%;
  padding: 15px; }

.searchbar-block .search-icon-box:after {
  color: #182b56; }

.searchbar-block .search-icon-box input {
  border: 1px solid #182b56; }

.searchbar-block .search-icon-box input::placeholder {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px; }

.left-searchbar-block {
  min-height: 618px; }

.left-searchbar-block .highlight {
  background: #ececec; }

.right-addbutton {
  margin: 0 auto; }

.model {
  height: 20%;
  width: 22%;
  color: #182b56;
  margin-top: 200px;
  margin-bottom: 100px; }

.location-model {
  margin-top: 120px; }

.loaction-map {
  float: left;
  margin: 10px 0;
  width: 100%; }

.location-model .btn {
  margin: 0 auto; }

.loaction-map > div {
  width: 100% !important; }

.location-model .hijnLS {
  top: 60px !important; }

.location-model .customWrapper {
  padding: 5px 16px;
  border-top: 1px solid #efefef; }

.user-model .btn {
  margin: 0 auto; }

.userModel {
  overflow: auto !important;
  max-height: 300px !important; }

.modelClose {
  margin-left: 350px; }

.leftchatbar {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  padding: 0; }

.rightchatbar, .rightchatbar .rightchatbarmobileview {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  float: left;
  min-height: 618px;
  width: 100%;
  position: relative; }

.rightchatbarmobileview {
  padding: 0;
  float: left;
  width: 100%; }

.leftchatbar .lc-155taeu {
  background: #eaecf2 !important;
  padding: 10px 20px !important;
  height: 46px; }

.messagelist .chatHeader {
  background: #233d7b;
  padding: 7px 15px;
  float: left;
  width: 100%; }

.messagelist .chatHeader .userchat-title {
  float: left;
  width: 60%; }

.messagelist .chatHeader .userchat-title .chat-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
  float: left; }

.messagelist .chatHeader .userchat-title .chat-title span {
  display: block;
  font-weight: normal;
  font-size: 11px;
  padding-top: 2px; }

.messagelist .chatHeader .rounded-circle {
  float: left;
  margin-top: 3px; }

.messagelist .chaticon-block {
  text-align: right; }

.messagelist .chaticon-block .nav-item button img {
  width: 7px; }

.messagelist .chaticon-block > ul > li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 12px;
  cursor: pointer; }

.messagelist .profile-item > button:after {
  display: none; }

.messagelist .mob-menu .profile-item .dropdown-menu:before {
  left: 20px;
  right: auto;
  bottom: -8px;
  top: auto;
  border-top: 8px solid #fff;
  border-bottom: 0; }

.messagelist .mob-menu .profile-item .dropdown-menu {
  left: -20px !important;
  top: -20px !important; }

.messagelist .chaticon-block .profile-item .dropdown-menu {
  left: auto !important;
  right: -17px !important;
  top: -65px !important;
  min-width: 170px; }

.messagelist .chaticon-block .account-detail:before {
  right: 8%; }

.nodata-found-block {
  margin-top: 100px; }

.messagelist .messagebox {
  background-color: rgba(35, 61, 120, 0.1);
  border-radius: 6px;
  font-size: 15px;
  color: #777777;
  text-align: left;
  min-width: 150px;
  padding: 6px 10px; }

.messagelist .messagebox > span {
  text-align: right;
  font-size: 11px;
  position: absolute;
  bottom: 5px;
  right: 10px; }

.messagelist .messagebox > span img {
  text-align: right;
  margin-left: 5px; }

.mes-block {
  float: left;
  width: 100%;
  max-height: 460px;
  height: 100vh;
  padding-bottom: 15px;
  overflow: auto;
  background-image: url(/static/media/chat-bg.9f29cb89.png);
  background-repeat: no-repeat;
  background-size: 100%; }

.mes-block .messagebox .file-size {
  font-size: 10px;
  position: absolute;
  bottom: 12px;
  left: 18px;
  background: #333;
  border-radius: 20px;
  color: #fff;
  padding: 2px 20px; }

.mes-block .messagebox .lc-3ft2j8 {
  white-space: normal; }

.mes-block .lc-81ie7q .messagebox, .mes-block .lc-5hv1ys .messagebox {
  position: relative; }

.mes-block .lc-5hv1ys .messagebox {
  background: #e6e9ef;
  float: right;
  width: 100%; }

.mes-block .lc-5hv1ys .messagebox:after {
  content: '';
  position: absolute;
  top: 0px;
  right: -10px;
  border-top: 15px solid #e6e9ef;
  border-left: 0px solid transparent;
  border-right: 15px solid transparent; }

.mes-block .lc-81ie7q .messagebox {
  background: #e6f6f5;
  float: left;
  width: 100%; }

.mes-block .lc-81ie7q .messagebox:after {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  border-top: 15px solid #e6f6f5;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent; }

.mes-block .messagebox .black-text {
  color: #000; }

.mes-block .messagebox .white-text {
  color: #fff; }

.mes-block .messagebox .white-text, .mes-block .messagebox .black-text {
  text-align: right;
  font-size: 10px;
  position: absolute;
  bottom: 12px;
  right: 20px; }

.mes-block .messagebox .chatvideo {
  background: #000;
  position: relative; }

.mes-block .messagebox .chatvideo > img {
  opacity: 0.5; }

.mes-block .messagebox .chatvideo .video-react .video-react-big-play-button {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  line-height: 36px;
  font-size: 20px; }

.mes-block .messagebox .chatvideo .video-react {
  width: 265px; }

.mes-block .messagebox .chatvideo .video-react-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.mes-block .messagebox .msg-chat {
  width: 100%;
  float: left;
  padding-bottom: 13px; }

.mes-block .messagebox .read-msg-icon {
  text-align: right;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  right: 15px; }

.mes-block .messagebox .msg-chat .msg-listbox {
  word-break: break-all; }

.mes-block .messagebox .video-react-control-bar.video-react-control-bar-auto-hide {
  height: 50px; }

.mes-block .messagebox .chatvideo .file-size, .mes-block .messagebox .chatvideo .white-text {
  bottom: 32px; }

.mes-block .lc-5hv1ys .messagebox.chataudio, .mes-block .lc-81ie7q .messagebox.chataudio {
  background: #f1f3f4;
  float: left;
  position: relative; }

.mes-block .lc-5hv1ys .messagebox.chataudio .rounded-circle, .mes-block .lc-5hv1ys .messagebox.chataudio .react-audio-player, .mes-block .lc-81ie7q .messagebox.chataudio .rounded-circle, .mes-block .lc-81ie7q .messagebox.chataudio .react-audio-player {
  float: left; }

.mes-block .lc-5hv1ys .messagebox.chataudio:before, .mes-block .lc-81ie7q .messagebox.chataudio:before {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 45px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAaCAYAAACHD21cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0MzMDlFNkU2RDY1MTFFOUIzNjE4M0NBMTZGQzRCMjQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0MzMDlFNkY2RDY1MTFFOUIzNjE4M0NBMTZGQzRCMjQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQzMwOUU2QzZENjUxMUU5QjM2MTgzQ0ExNkZDNEIyNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQzMwOUU2RDZENjUxMUU5QjM2MTgzQ0ExNkZDNEIyNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pv1v+bEAAAGpSURBVHja1JQ7SANBEIZzZ4xiIUIIaCEq2PsobIJYRBvRykelIERF7IONkMYHtoFYKKJgk8JGEMFXHUwh2AtWYpVWSczl/Ebmwnm5HLF04MuE3fl353Z3xrBtO+S2+eW1JG4d2kAmy3B0cX584o4zPaJ9XAZOYRwm9H9G52pmODsyMYm7ggFWf/cs2IN7hRnm7r07pmDPKxLTsV3Y8ku1C25Cje0WOn2/EbMChJYeVp2wCuEAYbiR8E/2T4VGE/G1mDCvYgr/AJUmhBXiZbOE/GShFUpNCEsamxVhUe/HdN+Tj9maqviiqS+iqoNB6Up5tWisZapA+IJYgDCmm/xoTC3YfpBy2QgQbsId9EFEhI+QpnQO8HFOLeVV6FicGCnmNBSMuaXVKH/epEghDy/wrBnIJyRgGAZhTIu912QVOdUFTUNWlWrPwYgKcox1ayuR+14Ujbt1TOMO4Um80yK0pcj3jYpn/PpXz9GgCG5bUxrSYUm7ADuIynXNyudAzrTfrPg+Ws+Ochgdel9OH03qxX+wiO1uB25rh0uIuh5DXp/lLHw6gd8CDAD25ZF3poEpqAAAAABJRU5ErkJggg==);
  height: 26px;
  width: 14px; }

.mes-block .messagebox.chataudio .react-audio-player {
  white-space: normal !important; }

.messagebox .chatimage {
  background: #000;
  width: 230px;
  text-align: center;
  height: 200px; }

.messagebox .chatimage img {
  opacity: 0.5; }

.mes-block .lc-5hv1ys .messagebox.chataudio:after {
  content: '';
  position: absolute;
  top: 0px;
  right: -10px;
  border-top: 15px solid #f1f3f4;
  border-left: 0px solid transparent;
  border-right: 15px solid transparent; }

.mes-block .lc-81ie7q .messagebox.chataudio:after {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  border-top: 15px solid #f1f3f4;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent; }

.messagebox .react-h5-audio-player.undefined {
  width: 260px; }

.messagebox .chatlocation {
  padding-bottom: 20px; }

.react-h5-audio-player.undefined > div {
  background-color: transparent !important;
  float: right;
  width: 200px !important;
  box-shadow: none !important;
  height: 50px !important;
  padding: 12px 0 5px 0 !important; }

.visualizer {
  display: none !important; }

.chataudio .toggle-play-button {
  background-color: transparent !important;
  color: #535c64 !important; }

.chataudio .toggle-play-button .play-icon {
  border-left: 12px solid #535c64 !important; }

.chataudio .volume-controls {
  display: none !important; }

.chataudio .audio-info .time {
  font-size: 12px; }

.chataudio .indicator {
  background: #535c64 !important;
  height: 14px !important;
  width: 14px !important;
  top: 1px !important; }

.chataudio .audio-info {
  position: absolute;
  bottom: -7px; }

.chataudio .toggle-play-wrapper {
  flex: 1 0 20px !important; }

.mes-block .messagebox .chatfile {
  background: #dde1ea;
  border-radius: 4px;
  padding: 10px;
  min-width: 300px; }

.mes-block .lc-81ie7q .messagebox .chatfile {
  background: #d2eae9; }

.mes-block .messagebox .chatfile img {
  margin-right: 10px; }

.mes-block .messagebox .chatfile-page strong {
  font-weight: normal;
  font-size: 13px; }

.typing-clr {
  color: #182b56; }

.messagelist .form-group textarea {
  border: 1px solid #182b56;
  font-size: 16px;
  color: rgba(4, 2, 3, 0.8);
  height: 44px !important;
  line-height: 26px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding-right: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 65px;
  position: relative; }

.lc-1lt0t9n, .attacheicon:after {
  display: none; }

.messagelist .profile-item.show {
  background: none; }

.messagelist .lc-1fjhbkf {
  position: relative; }

.messagelist .attacheicon {
  position: absolute;
  top: 8px;
  left: 30px;
  z-index: 9; }

.messagelist .audioicon {
  position: absolute;
  top: 10px;
  right: 30px; }

.white-bg {
  box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
  padding: 40px; }

.chatlist-item .chatname-title span {
  display: block;
  font-size: 12px;
  color: #777777;
  font-weight: normal; }

.chatlist-item .e1fut3qs0 {
  font-size: 14px !important;
  color: #777;
  margin-top: 5px;
  font-weight: 600;
  opacity: 9 !important; }

.chatlist-item .e1fut3qs0 img {
  margin-right: 4px; }

.chatlist-item .chattime {
  font-size: 11px !important; }

.messagelist .voice-recording {
  width: 100%;
  padding: 29px 5px;
  float: left;
  border-top: 1px solid #f9f9f9;
  position: absolute;
  bottom: 0; }

.messagelist .voice-recording .recorder-icon {
  padding-left: 30px;
  color: #ccc;
  font-size: 14px;
  float: left;
  position: relative; }

.messagelist .voice-recording .recorder-icon:after {
  position: absolute;
  left: 8px;
  top: 6px;
  content: "";
  background-color: #d90000;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  float: left; }

.messagelist .voice-recording button {
  background: none;
  border: none;
  vertical-align: bottom; }

.messagelist .voice-recording .recorder-time {
  float: right; }

.messagelist .voice-recording img {
  vertical-align: sub; }

.left-searchbar-block .search-noresult {
  text-align: center;
  padding-top: 150px; }

.search-d-none {
  display: none; }

.chatlist-item {
  position: relative; }

.message-count {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #00a8a0;
  font-size: 11px;
  top: 47px;
  right: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff; }

.unread-message-time {
  font-weight: 700;
  color: #00a8a0; }

.more-chat {
  float: left;
  margin-left: 140px; }

.left-chatlist {
  float: left;
  width: 100%;
  max-height: 548px;
  overflow: auto; }

/* width */
.white-bg ::-webkit-scrollbar {
  width: 10px; }

/* Track */
.white-bg ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px; }

/* Handle */
.white-bg ::-webkit-scrollbar-thumb {
  background: #233d7b;
  border-radius: 10px; }

/* Handle on hover */
.white-bg ::-webkit-scrollbar-thumb:hover {
  background: #3d5694; }

.notification-item .dropdown-menu {
  min-width: 300px !important;
  left: -199px !important; }

.alert {
  padding: 20px;
  background-color: #f7f7f7;
  color: #191919;
  width: 325px;
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 1;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border-left: 5px solid #00a8a0; }

.alert strong {
  float: left;
  padding-right: 10px; }

.closebtn {
  margin-left: 15px;
  color: #000;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s; }

.closebtn:hover {
  color: black; }

.ril__image, .ril__imagePrev, .ril__imageNext {
  border: 5px solid #fff;
  border-radius: 10px; }

.ril__navButtonPrev {
  background-color: #233d7b; }

.ril__navButtonNext {
  background-color: #233d7b; }

.delete-profile {
  width: 30px !important;
  height: 30px;
  padding: 0;
  min-width: auto;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: -10px;
  right: 148px; }

.delete-profile .fa {
  padding-right: 0px;
  font-size: 30px; }

.profile-add > div {
  width: 250px;
  margin: auto; }

/*RESPONSIVE CSS START*/
.singlebusiness-block .searchresult-categoryblock .serv-name {
  font-size: 22px;
  font-weight: 700; }

.singlebusiness-block .searchresult-categoryblock .locationname, .singlebusiness-block .searchresult-categoryblock .phonename {
  font-size: 15px;
  width: auto;
  font-weight: normal; }

.more_details .phonename img {
  opacity: 0.5; }

.singlebusiness-block .searchresult-categoryblock .locationname span {
  color: #ff7700;
  padding-left: 10px; }

.singlebusiness-block .searchresult-categoryblock .locationname span strong {
  color: #000;
  font-size: 14px;
  padding-left: 5px; }

.singlebusiness-block .searchresult-categoryblock .map-location:after {
  display: none; }

.singlebusiness-block .map-location {
  float: right;
  margin: 20px;
  border: 5px solid #ebebeb;
  height: auto; }

.singlebusiness-block .searchresult-categoryblock .searchcat-title-box ul li {
  margin: 0;
  padding: 12px 0; }

.singlebusiness-block .searchresult-categoryblock {
  margin-bottom: 30px; }

.singlebusiness-block .review-listing {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px; }

.singlebusiness-block .all-review-block {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 25px;
  margin-bottom: 25px; }

.singlebusiness-block .beauty-box .card-header::after {
  display: none; }

.singlebusiness-block .beauty-box .card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 36px;
  min-height: 36px;
  line-height: 36px;
  padding: 0;
  text-transform: none;
  font-size: 16px;
  font-weight: normal; }

.singlebusiness-block .beauty-box .card {
  height: 160px; }

/*Switch Button CSS Start*/
.switch-btn .react-switch-bg {
  height: 20px !important;
  width: 48px !important; }

.switch-btn .switch-label {
  line-height: 17px;
  padding-left: 10px;
  float: right; }

.switch-btn label {
  margin: 0;
  width: 28px;
  margin-right: 20px; }

.switch-btn.form-group {
  display: flex; }

.react-switch-bg > div {
  display: none !important; }

.react-switch-handle {
  height: 18px !important;
  width: 18px !important; }

.second-header:disabled {
  background: #ccc; }

.disable:before, .inner-header.disable .sticky-top:before, .second-header.disable:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 9;
  cursor: no-drop; }

.second-header.disable:before {
  position: absolute;
  top: auto;
  left: 0;
  display: block;
  width: 100%;
  height: 60px;
  content: "";
  z-index: 1;
  cursor: no-drop; }

.gallery-block .beauty-box .card img {
  height: 100%;
  margin: 0 auto; }

.gallery-block .beauty-box {
  border: 1px solid #ccc; }

.react-phone-number-input__row {
  /* This is done to stretch the contents of this component */
  display: flex;
  align-items: center; }

.react-phone-number-input__phone {
  flex: 1 1;
  background: none;
  padding: 0 !important;
  min-width: 0;
  margin-top: -4px; }

.react-phone-number-input__input:focus {
  box-shadow: none !important;
  border: none !important; }

.react-phone-number-input__icon {
  width: 40px !important;
  /* box-sizing: content-box; */
  position: relative;
  height: 40px !important;
  line-height: 38px; }

.react-phone-number-input__icon:after {
  position: absolute;
  content: "+234";
  width: 40px;
  left: 0; }

img.react-phone-number-input__icon-image, .react-phone-number-input__icon svg {
  display: none; }

.react-phone-number-input__icon--international {
  /* The international icon size is square */
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);
  /* The international icon size is square hence the exact `padding` value */
  /* for precise alignment with `4x3` sized country flags. */
  padding-left: 0.155em;
  padding-right: 0.155em;
  border: none; }

.react-phone-number-input__error {
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
  margin-top: calc(0.3rem);
  color: #D30F00; }

.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 2px; }

.react-phone-number-input__icon-image img {
  padding-bottom: 2px; }

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
  margin: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important; }

.react-phone-number-input__ext-input {
  width: 3em; }

.react-phone-number-input__ext {
  white-space: nowrap; }

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em; }

/* Styling native country `<select/>`. */
.react-phone-number-input__country--native {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em; }

.react-phone-number-input__country-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }

.react-phone-number-input__country-select-arrow {
  display: block;
  content: '';
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #B8BDC4;
  opacity: 0.7;
  transition: color 0.1s; }

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider {
  font-size: 1px;
  background: black; }

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: #03B2CB; }

/* Styling phone number input */
.react-phone-number-input__input {
  height: calc(0.3rem * 6);
  outline: none;
  padding: 20px;
  appearance: none;
  border: none;
  transition: border 0.1s;
  font-size: inherit; }

img.react-phone-number-input__icon-image {
  margin-bottom: 10px; }

.react-phone-number-input__input:focus {
  border-color: #03B2CB; }

.react-phone-number-input__input--disabled {
  cursor: default; }

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus {
  border-color: #EB2010; }

.react-phone-number-input__input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset; }

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
  border-bottom: none; }

.typing-indicator.right {
  float: right;
  padding-right: 40px;
  padding: 0;
  margin-right: 30px; }

.typing-indicator {
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding-left: 22px;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;
  float: left; }
  .typing-indicator span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #233d7b;
    display: block;
    border-radius: 50%;
    opacity: 0.5; }
    .typing-indicator span:nth-of-type(2) {
      animation: 2s blink infinite 0.55555s; }
    .typing-indicator span:nth-of-type(2) {
      animation: 2s blink infinite 1.1111s; }
    .typing-indicator span:nth-of-type(2) {
      animation: 2s blink infinite 1.66665s; }

.animation {
  transition: transform 400ms ease 0s; }

@keyframes blink {
  50% {
    opacity: 1; } }

@keyframes bulge {
  50% {
    transform: scale(1.05); } }

/*RESPONSIVE CSS START*/
@media only screen and (min-width: 1200px) and (max-width: 1349px) {
  .sticky-form-block .sticky-location-block, .sticky-form-block .sticky-search-block {
    width: 180px;
    margin-right: 10px; }
  .serv-name > span {
    max-width: 350px; }
  .p-growl.p-growl-topright {
    top: 130px !important; } }

/* Landscape tablets and medium desktops */
@media only screen and (max-width: 1199px) {
  .search-block {
    width: 425px; }
  .navbar-nav {
    margin-bottom: 0; }
  .serv-name > span {
    max-width: 350px; }
  .Social-icon .google-clr {
    margin-top: 10px !important; }
  .Social-icon span {
    margin-top: 10px !important; }
  .more-chat .btn {
    width: 100px; }
  .review-detail ul li {
    float: left;
    width: 100% !important;
    text-align: left; }
  .review-detail ul li:last-child {
    padding-left: 0; }
  .review-detail ul li span {
    float: left;
    margin-right: 10px;
    font-size: 12px !important; }
  .review-detail .reviewer-name {
    line-height: inherit; }
  .his-servicename {
    float: right !important;
    width: inherit !important;
    text-align: left !important;
    font-size: 12px !important; }
  .review-detail .date .times-hired {
    float: right !important; }
  .review-detail .date .times-hired span {
    float: right !important;
    font-size: 12px !important; }
  .review-detail .date .times-hired .btn {
    float: right !important;
    font-size: 11px; }
  .review-detail .times-hired {
    width: 103px; }
  .review-detail .business-history-date {
    font-size: 12px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-banner {
    height: 520px; }
  .main-header .nav-link {
    font-size: 12px; }
  .more-chat {
    margin-left: 112px; } }

/* Landscape tablets and medium desktops */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  section, footer {
    padding: 40px 0; }
  .sticky-form-block .sticky-location-block, .sticky-form-block .sticky-search-block {
    width: 170px;
    margin-right: 10px; }
  .inner-header {
    height: 62px; }
  .second-header .navbar-nav .nav-link {
    padding: 0 3px; }
  .second-header .navbar-nav .nav-item {
    line-height: normal; }
  .navbar .btn {
    border: 1px solid #fff; }
  .search-block::after, .location-block::after {
    top: 7px; }
  .main-header .nav-item {
    margin-right: 0; }
  .caption {
    padding-top: 100px; }
  .home-form-block {
    width: 100%;
    margin-bottom: 50px; }
  .banner-title {
    font-size: 24px;
    line-height: 34px; }
  .home-form-block .form-control, .home-form-block .btn {
    font-size: 20px;
    height: 40px;
    line-height: 40px; }
  .services-block .slick-slide img, .popular-service-block .card-header img {
    width: 100%; }
  .beauty-box .card-title {
    font-size: 16px;
    min-height: 70px; }
  .join-now-block h3 {
    font-size: 20px; }
  .join-now-block .btn {
    font-size: 18px;
    line-height: 44px; }
  .mobile-app-block .app-detail-box h3, .subscription-block h4 {
    font-size: 26px; }
  .subscription-block .form-control {
    width: 63%; }
  .subscription-block .btn {
    min-width: 35%; }
  .mobile-app-block .app-detail-box {
    text-align: left !important; }
  .mobile-app-block .app-detail-box .get-notify .form-control {
    max-width: 60%; }
  .mobile-app-block .app-detail-box .get-notify .btn {
    min-width: 35%; }
  .mobile-app-block .app-detail-box .get-notify {
    margin-bottom: 40px; }
  .testimonials-block {
    padding: 40px 0 80px 0; }
  .footer-keepin-touch li {
    width: 100%;
    text-align: left; }
  .footer-links, .footer-keepin-touch, .footer-social {
    margin-bottom: 20px;
    float: left; }
  .footer-social ul li a {
    width: 26px;
    height: 26px;
    line-height: 26px; }
  .inner-banner-section {
    height: 460px; }
  .inner-banner-section .home-form-block {
    display: inline-block;
    margin-top: 130px; }
  .profile-item {
    width: auto !important; }
  .chaticon-block .navbar-nav {
    padding-bottom: 0; }
  .business-brand {
    margin-right: 10px;
    width: 100px; }
  .business-brand-detail h3 {
    font-size: 15px; }
  .business-brand-detail {
    width: 65%; }
  .business-brand-detail li {
    font-size: 12px;
    margin-right: 8px; }
  .date .btn {
    font-size: 12px;
    min-width: 60px;
    margin-left: 10px; }
  .business-listing .edit-btn {
    top: 5px; }
  .review-service-list ul li {
    width: 28%; }
  .profile-item > a:after, .profile-item > button.link:after {
    right: -15px; }
  .second-header {
    margin-top: 15px; }
  .inner-section {
    min-height: 768px; }
  .inner-banner-section {
    display: none; }
  .p-growl.p-growl-topright {
    top: 135px !important; } }

/* Portrait tablets and small desktops */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /*.sticky-top { background-color:#00a8a0;}*/
  /*.navbar {height: auto;background-image: linear-gradient(to right, #00a8a0, #233d7b);padding: 7px 15px;}*/
  .inner-header .user-pic {
    display: none !important; }
  .navbar .btn {
    border: 1px solid #fff; }
  .main-header .nav-item {
    width: auto;
    margin: 0 auto; }
  .mobile-app-block .app-detail-box, .mobile-app-block .mobile-app-pic {
    text-align: center !important; }
  .caption {
    padding-top: 80px; }
  .banner-title span {
    font-size: 22px; }
  .search-block, .location-block, .home-form-block .btn {
    width: 100%;
    margin-left: 0;
    margin: 20px 0; }
  .search-block::after, .location-block::after {
    top: 4px; }
  .testimonial-box .card {
    text-align: center; }
  .testimonial-box .card .card-title img {
    float: none;
    margin: 0 auto; }
  .inner-header .navbar-collapse {
    text-align: center; }
  .inner-header .navbar-collapse .sticky-form-block {
    display: inline-block !important;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px; }
  .inner-header .nav-item {
    width: auto;
    margin: 0 auto;
    padding: 5px; }
  .inner-header .nav-item.profile-item.show {
    background: transparent; }
  .home_new .sticky-top .nav-item {
    padding: 9px 20px 9px 10px; }
  .inner-header .nav-link {
    display: inline-block;
    font-size: 14px; }
  .inner-header .navbar-collapse .sticky-form-block .sticky-location-block:after, .inner-header .navbar-collapse .sticky-form-block .sticky-search-block:after {
    left: 10px; }
  /*.inner-header .sticky-form-block { display: block; background-image: linear-gradient(to right, #00a8a0, #233d7b); position: absolute; width: 100%; height: 200px;
bottom: 0; top: 60px; left: 0; right: 0; padding: 15px;display: none; }*/
  /*.inner-header .sticky-form-block .sticky-location-block, .inner-header .sticky-form-block .sticky-search-block, .inner-header .sticky-form-block .btn { width: 100%; margin-right: 0;
margin-bottom: 15px !important;}*/
  .sticky-form-block .sticky-location-block, .sticky-form-block .sticky-search-block {
    width: 130px;
    margin-right: 10px; }
  .search-block input, .sticky-search-block input, .react-autosuggest__input {
    padding-right: 5px; }
  .inner-header .sticky-top {
    padding-bottom: 8px;
    padding-top: 8px; }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row; }
  .navbar-toggler {
    display: none; }
  .inner-header .navbar-nav.mob-menu {
    margin-left: auto;
    padding: 0;
    display: block;
    position: relative; }
  .inner-header .navbar-nav.mob-menu .nav-item {
    padding: 5px 20px 9px 10px !important;
    margin: 0 auto;
    float: left; }
  .profile-item .dropdown-menu {
    left: -144px;
    top: -10px;
    position: absolute; }
  .account-detail:before {
    right: 14%; }
  .notification-item .account-detail:before {
    right: 25%; }
  .notification-item .dropdown-menu {
    left: -215px !important; }
  .mob-search img {
    width: 30px; }
  .mob-search {
    line-height: 56px; }
  .fixed-header .nav-item {
    padding: 5px 20px 9px 10px !important; }
  .slick-prev, .slick-next {
    background-color: #00a8a0 !important;
    border: 0 !important; }
  .category-slider {
    background-color: #fff;
    margin: 40px 0;
    padding: 0 20px; }
  .category-slider .slick-slide > div {
    margin: 0 10px; }
  .category-box p {
    color: #000;
    font-size: 20px; }
  .category-box {
    border: 1px solid #e7e7e7 !important; }
  .category-box:first-child {
    border-right: initial; }
  .category-box:hover {
    border: 1px solid #00a8a0 !important; }
  .category-box .category-icon.beauty-icon {
    background-position: -22px -97px; }
  .category-box .category-icon.repair-icon {
    background-position: -92px -97px; }
  .category-box .category-icon.event-icon {
    background-position: -162px -97px; }
  .category-box .category-icon.restaurant-icon {
    background-position: -232px -97px; }
  .category-box .category-icon.photo-icon {
    background-position: -302px -97px; }
  .category-box .category-icon.travel-icon {
    background-position: -368px -97px; }
  .popular-service-block, .category-slider {
    display: none; }
  .popular-service-block.active, .category-slider.active {
    display: block; }
  .second-header {
    padding-top: 15px;
    margin-top: 12px; }
  .second-header .navbar-nav .dropdown-menu {
    background-color: #f0f0f0;
    box-shadow: none; }
  .second-header .navbar-nav .nav-item {
    line-height: 40px; }
  .second-header .navbar-nav .nav-link {
    font-size: 12px;
    padding: 0 4px; }
  .second-header .navbar-nav .dropdown-menu {
    z-index: 2;
    line-height: 26px;
    min-width: 240px;
    border: 0;
    border-radius: 0;
    box-shadow: 0px 3px 24px 0 rgba(0, 0, 0, 0.21);
    padding: 0;
    overflow: auto;
    height: 300px;
    position: absolute;
    background-color: #ffffff; }
  /*.second-header { height: auto; border: 0;}*/
  /*.second-header #secondmenu { background: #f9f9f9; z-index: 1; box-shadow: 0 0 21.4px 2.6px rgba(0, 0, 0, 0.2); position: fixed; width: 95%; top: 82px; left: 0; right: 0; margin: 0 auto; }*/
  .second-header .navbar-toggler {
    background-image: linear-gradient(to right, #00a8a0, #233d7b);
    border: 0;
    position: fixed;
    top: 13px;
    z-index: 9;
    left: 75px;
    padding: 5px; }
  .second-header .navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAlCAYAAAA9ftv0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZFNzI4N0JEMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZFNzI4N0JFMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkU3Mjg3QkIyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkU3Mjg3QkMyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ykQ3HAAABLklEQVR42mL8//8/AxD0AHEkEPMwDG7wHYjXAnEuI9Dh7UBGBcPQAtNBDn8JZIgNMYd/ZwISzxmGHngJCnE7IOMAEDMOIYd7M0IzpzoQRwAx7yB38DcgXgPEl2AOH3KAiWGIglGHjzp81OGjDh91OBiwQGk9II6iUbP2LRAvAOL71DQUVHO6AOndNA6gn0BsDsQXqenwy0Bahw6xuwuI3amZxkXplCwlqJ05Z9LJ4VOpnTnrgZgZmjlp0ax9B8SzgXgWtTMnMp+DBg7/QYvoG22Pjzp81OGjDh91+KjDB7Q9PthGsu5Dmwi/8dWc9kB6P8PgGzsENbetgfgzrqQykWFwDnjqAnEOvjQuOYiTsiI+h88bxA5fgC9zVgIxK8PgmgN6BMRtQHwMlwKAAAMAszFG6laeJF0AAAAASUVORK5CYII=);
    height: 22px;
    width: 24px; }
  .contact-detail-pic {
    height: 300px;
    width: 100%; }
  .categoryblock-img {
    width: 32%; }
  .categoryblock-img img {
    width: 100%; }
  .categoryservice-block {
    width: 60%;
    margin-left: 15px;
    margin-top: 15px; }
  .search-result-right {
    padding: 0; }
  .back_btn_mob {
    display: block;
    margin-bottom: 20px;
    color: #fff;
    line-height: 48px;
    margin-right: 20px; }
  .messagelist .back_btn_mob {
    display: block;
    float: left;
    color: #fff;
    line-height: 48px;
    margin-right: 20px;
    margin-bottom: 0; }
  .messagelist .mob-menu {
    padding: 0; }
  .leftside-desktop {
    display: none; }
  .right-sidebarblock {
    padding-left: 50px; }
  .account-detail ul li button.link {
    font-size: 14px; }
  .tabbing-block .nav-tabs .nav-link {
    font-size: 14px; }
  .back_btn_mob {
    display: none; }
  .rightchatbarmobileview, .leftchatbarMobileView {
    display: none; }
  .white-bg {
    padding: 0;
    box-shadow: none; }
  .serv-name > span {
    max-width: 100%; }
  .sticky-form-block .sticky-location-block:after, .sticky-form-block .sticky-search-block:after {
    left: 14px; }
  .search-block input, .sticky-search-block input, .react-autosuggest__input {
    font-size: 12px; }
  /*.leftside-desktop, .left-sidebar, .right-sidebar { display: none}*/
  .contact-banner.d-none {
    display: block !important; }
  /*.inner-header .sticky-form-block { display: none}*/
  .search-result-right .moreinfo .date {
    width: 100%;
    text-align: left;
    margin-bottom: 10px; }
  .more-chat {
    margin-left: 30px; }
  .inner-header .sticky-form-block {
    margin: 0 auto 0 0; }
  .inner-header .navbar .mr-auto, .mx-auto {
    margin-right: 0 !important; }
  .inner-header .btn {
    min-width: 120px;
    font-size: 14px; }
  .fixed-header .mr-auto, .mx-auto {
    margin-right: 0 !important; }
  .fixed-header .sticky-form-block {
    margin: 0 auto 0 0; }
  .fixed-header .nav-item {
    padding: 5px !important; }
  .fixed-header .mob-menu .nav-item {
    padding: 5px 20px 9px 10px !important; }
  .fixed-header .btn {
    min-width: 120px;
    font-size: 14px; }
  .fixed-header .nav-link {
    font-size: 14px; }
  .text-right {
    text-align: center !important; } }

/* Landscape phones and portrait tablets */
@media only screen and (max-width: 767px) {
  body.modal-open {
    padding-right: 0 !important; }
  .navbar .btn {
    border: 1px solid #fff; }
  .btn, a.btn {
    min-width: 70%;
    padding-left: 20px;
    padding-right: 20px; }
  .btn-center {
    text-align: center !important; }
  .btn-center .btn {
    margin-top: 20px;
    margin-left: 0 !important; }
  .btn-center .btn:first-child {
    margin-top: 0; }
  .ml-2 {
    margin-left: 0 !important; }
  section, footer {
    padding: 40px 0; }
  .extra-pedding {
    padding-top: 40px; }
  .more-chat {
    float: right; }
  .more-chat .btn {
    width: 100px; }
  .fixed-header .sticky-form-block, .header-logo, .banner-title span {
    display: none; }
  .sticky-logo {
    display: block;
    width: 40px; }
  .main-header {
    position: relative;
    top: 0;
    height: auto; }
  #mainmenu .nav-link {
    font-size: 20px;
    margin: 5px auto;
    min-width: 160px;
    padding: 0px; }
  /*.main-header .navbar-collapse.collapse.show .nav-link  { line-height: 20px; }
#mainmenu .nav-item { line-height: 20px; }*/
  .main-header .navbar-collapse.collapse.show .nav-link {
    height: auto; }
  #mainmenu .nav-item {
    float: inherit; }
  .fixed-header {
    width: 100%; }
  .navbar {
    height: auto;
    background-image: linear-gradient(to right, #00a8a0, #233d7b);
    padding: 7px 15px; }
  .navbar-nav {
    padding-bottom: 0;
    display: block; }
  .main-header .nav-item {
    margin-right: 0;
    padding: 0; }
  .banner-title {
    font-size: 20px;
    line-height: 25px;
    padding: 0;
    margin: 5px 0; }
  li.nav-item.profile-item {
    margin-right: 0; }
  .inner-header .navbar-brand {
    float: left;
    margin: 0; }
  .inner-header .navbar-collapse {
    text-align: center; }
  .inner-header .navbar-collapse .sticky-form-block {
    display: inline-block !important;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 80%; }
  .inner-header .nav-item {
    float: left;
    padding: 0px 8px;
    margin: 0 auto; }
  .inner-header .navbar-nav {
    display: block;
    margin-bottom: 0; }
  .inner-header .nav-item.profile-item.show {
    background: transparent; }
  .inner-header .nav-link {
    display: inline-block; }
  .inner-header .navbar-collapse .sticky-form-block .sticky-location-block:after, .inner-header .navbar-collapse .sticky-form-block .sticky-search-block:after {
    left: 10px; }
  .inner-header .navbar-collapse .sticky-form-block .sticky-location-block, .inner-header .navbar-collapse .sticky-form-block .sticky-search-block {
    width: 100%;
    float: none;
    margin: 10px auto; }
  .inner-header .navbar-nav.mob-menu {
    margin-left: auto;
    text-align: center;
    padding: 0;
    position: relative; }
  .inner-header .navbar-nav.mob-menu .nav-item {
    padding: 0px 10px;
    margin: 0 auto;
    float: left; }
  .mob-search img {
    width: 30px; }
  .mob-search {
    line-height: 56px; }
  .account-detail:before {
    right: 14%; }
  .profile-item > a:after, .account-detail, .profile-item > button.link:after {
    right: -14px;
    top: 5px; }
  .profile-item .dropdown-menu {
    left: -150px;
    top: -19px;
    position: absolute; }
  .mes-block .messagebox .chatfile {
    min-width: 100%;
    width: 270px; }
  .p-growl.p-growl-topright {
    top: 63px !important; }
  .inner-header .sticky-form-block {
    display: block;
    background-image: linear-gradient(to right, #00a8a0, #233d7b);
    position: absolute;
    width: 100%;
    height: 200px;
    bottom: 0;
    top: 60px;
    left: 0;
    right: 0;
    padding: 15px;
    display: none; }
  .inner-header .sticky-form-block .sticky-location-block, .inner-header .sticky-form-block .sticky-search-block, .inner-header .sticky-form-block .btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px !important; }
  .second-header {
    height: 57px !important; }
  .second-header .navbar-nav .dropdown-menu {
    background-color: #f0f0f0;
    box-shadow: none; }
  .second-header .navbar-nav .nav-item {
    line-height: 40px; }
  .second-header .navbar-nav .nav-link {
    font-size: 15px;
    padding: 0 10px;
    margin: 0 auto;
    width: 100%;
    text-align: left; }
  .second-header #secondmenu {
    background: #f9f9f9;
    z-index: 1;
    box-shadow: 0 0 21.4px 2.6px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 95%;
    top: 61px;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .second-header .navbar-toggler {
    background-image: linear-gradient(to right, #00a8a0, #233d7b);
    border: 0;
    position: fixed;
    top: 13px;
    z-index: 9;
    left: 75px;
    padding: 5px; }
  .second-header .navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAlCAYAAAA9ftv0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZFNzI4N0JEMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZFNzI4N0JFMkI3RDExRTlCQjRGOTFFQjI3RURDOTRDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkU3Mjg3QkIyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkU3Mjg3QkMyQjdEMTFFOUJCNEY5MUVCMjdFREM5NEMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7ykQ3HAAABLklEQVR42mL8//8/AxD0AHEkEPMwDG7wHYjXAnEuI9Dh7UBGBcPQAtNBDn8JZIgNMYd/ZwISzxmGHngJCnE7IOMAEDMOIYd7M0IzpzoQRwAx7yB38DcgXgPEl2AOH3KAiWGIglGHjzp81OGjDh91OBiwQGk9II6iUbP2LRAvAOL71DQUVHO6AOndNA6gn0BsDsQXqenwy0Bahw6xuwuI3amZxkXplCwlqJ05Z9LJ4VOpnTnrgZgZmjlp0ax9B8SzgXgWtTMnMp+DBg7/QYvoG22Pjzp81OGjDh91+KjDB7Q9PthGsu5Dmwi/8dWc9kB6P8PgGzsENbetgfgzrqQykWFwDnjqAnEOvjQuOYiTsiI+h88bxA5fgC9zVgIxK8PgmgN6BMRtQHwMlwKAAAMAszFG6laeJF0AAAAASUVORK5CYII=);
    height: 22px;
    width: 24px; }
  .inner-header .navbar-toggler {
    padding: .25rem 0rem .25rem 1.5rem; }
  .inner-header .navbar-collapse .sticky-form-block .btn {
    min-width: 100%;
    margin-top: 10px; }
  .home-form-block {
    width: 100%;
    margin-bottom: 50px; }
  .search-block, .location-block, .home-form-block .btn {
    width: 100%;
    margin-left: 0;
    margin: 20px 0; }
  .slick-prev {
    left: -20px !important;
    z-index: 1; }
  .slick-next {
    right: -20px !important;
    z-index: 1; }
  .tabbing-block li {
    display: block;
    width: 50%;
    text-align: center; }
  .pagination-block .pagination li {
    display: inline-block !important;
    width: auto !important; }
  .pagination-block .pagination li.disabled a {
    background-color: #dddddd !important; }
  .slick-prev, .slick-next {
    background-color: #00a8a0 !important;
    border: 0 !important; }
  .category-slider {
    background-color: #fff;
    margin: 40px 0;
    padding: 0 20px; }
  .category-slider .slick-slide > div {
    margin: 0 10px; }
  .category-box p {
    color: #000;
    font-size: 20px; }
  .category-box {
    border: 1px solid #e7e7e7 !important;
    min-height: 160px; }
  .category-box:first-child {
    border-right: initial; }
  .category-box:hover {
    border: 1px solid #00a8a0 !important; }
  .category-box .category-icon.beauty-icon {
    background-position: -22px -97px; }
  .category-box .category-icon.repair-icon {
    background-position: -92px -97px; }
  .category-box .category-icon.event-icon {
    background-position: -162px -97px; }
  .category-box .category-icon.restaurant-icon {
    background-position: -232px -97px; }
  .category-box .category-icon.photo-icon {
    background-position: -302px -97px; }
  .category-box .category-icon.travel-icon {
    background-position: -368px -97px; }
  .tabbing-block, .category-icon-hide-mobile {
    display: block !important; }
  .category-icon {
    display: none; }
  .subscription-block .form-control, .subscription-block .btn {
    width: 100%; }
  .popular-service-block, .category-slider {
    display: none; }
  .popular-service-block.active, .category-slider.active {
    display: block; }
  .searchcat-title-box ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  .home-banner {
    padding-top: 80px;
    height: 500px; }
  .main-heading {
    text-align: center; }
  .main-heading:before, .main-heading:after {
    left: 40%; }
  .caption, .home-banner .banner-content {
    display: block !important;
    height: auto;
    margin-bottom: 40px;
    padding-top: 0; }
  .popular-box .card-title {
    font-size: 17px; }
  .popular-box .card-header img {
    height: auto; }
  .popular-box .popular-review {
    padding-left: 5px; }
  .beauty-box .card-title {
    font-size: 12px;
    min-height: 60px;
    font-weight: 700; }
  .howit-works-desktop, .how-it-works-block p {
    display: none; }
  .howit-works-slider {
    display: block; }
  .howit-works-slider .step-pic img {
    display: block;
    margin: 0 auto; }
  .howit-works-slider .slick-prev, .howit-works-slider .slick-next {
    display: none !important; }
  .howit-works-slider p {
    display: block;
    font-size: 23px;
    line-height: 34px;
    font-weight: 700;
    color: #1aaea8;
    text-align: center; }
  .howit-works-slider .btn {
    float: none !important;
    text-align: center;
    min-width: 75%;
    margin-left: 0 !important; }
  .join-now-block h3 {
    line-height: 34px; }
  .join-now-block .btn {
    font-size: 16px;
    font-weight: normal;
    float: none !important;
    margin-top: 30px; }
  .join-now-block {
    text-align: center; }
  .mobile-app-block .app-detail-box, .mobile-app-block .mobile-app-pic {
    text-align: center !important; }
  .testimonials-block {
    padding: 40px 0 80px 0; }
  .testimonials-block .slick-prev, .testimonials-block .slick-next {
    display: none !important; }
  .testimonial-box .card {
    text-align: center; }
  .testimonial-box .card .card-title img {
    float: none;
    margin: 0 auto; }
  .testimonials-block .container {
    padding: 0; }
  .mobile-app-block {
    padding-top: 40px; }
  .mobile-app-block .app-detail-box h3, .subscription-block h4 {
    font-size: 24px; }
  .mobile-app-block .app-detail-box .get-notify .form-control {
    max-width: 100%;
    margin-bottom: 20px; }
  .mobile-app-block .app-detail-box .get-notify .btn {
    min-width: 100%; }
  .mobile-app-block .app-detail-box .get-notify form {
    margin-bottom: 20px; }
  .mobile-app-block .app-detail-box .get-notify {
    margin-bottom: 40px; }
  .mobile-app-block .app-detail-box a img {
    width: auto;
    margin-right: 5px !important;
    margin-top: 10px;
    margin-left: 5px; }
  .mobile-app-block .mt-5 {
    margin-top: 0 !important; }
  .how-it-works-block {
    margin-top: 30px; }
  .subscription-block .pl-0 {
    padding-left: 15px !important; }
  .subscription-block .btn {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #fff; }
  .services-block .slick-slide img, .popular-service-block .card-header img {
    width: 100%; }
  .forms {
    width: auto; }
  .forms .modal-footer p {
    float: none;
    text-align: center; }
  .forms .modal-footer {
    text-align: center; }
  .Social-icon ul .google-clr {
    margin: 0 10px; }
  .Social-icon ul li a {
    padding: 0 15px;
    text-align: center; }
  .Social-icon ul li a span {
    padding-right: 10px; }
  .forms .with-signup:before {
    right: 20%; }
  .forms .with-signup:after {
    left: 20%; }
  .forms.modal-dialog {
    width: auto; }
  .footer-title {
    margin-bottom: 15px; }
  .footer-keepin-touch {
    margin-bottom: 20px; }
  .footer-keepin-touch li {
    width: 100%;
    text-align: left; }
  .footer-links, .footer-keepin-touch, .footer-social {
    margin-bottom: 30px;
    float: left; }
  .sticky-footer {
    display: block; }
  .inner-footer {
    margin-bottom: 55px; }
  /*.footer-block > div.container { display: none}*/
  .footer-block {
    display: none; }
  .home_new .footer-block {
    display: block !important; }
  body .p-dropdown-panel .p-dropdown-filter-container {
    width: 100%; }
  body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
    height: 40px; }
  .account-detail ul li button.link {
    font-size: 15px;
    width: 100%;
    text-align: left; }
  .footer-logo p {
    display: none; }
  .footer-links li {
    margin-right: 5px; }
  .footer-links li a {
    padding: 1px 8px; }
  .get-notify .link > img {
    margin: 10px !important; }
  .home_new .footer-block {
    display: block !important; }
  /*Inner Page*/
  .forms .modal-title {
    font-size: 24px;
    line-height: 28px; }
  .right-sidebar {
    padding-left: 15px;
    padding-right: 15px; }
  .right-sidebar {
    display: none; }
  .inner-header .user-pic {
    display: none !important; }
  .right-sidebar .btn.ml-3 {
    margin-left: 0 !important; }
  .business-brand-detail {
    width: 100%; }
  .business-brand-detail li {
    line-height: 35px;
    margin-right: 30px;
    width: auto; }
  .review-detail ul li {
    width: 100%;
    margin-bottom: 10px; }
  .review-detail ul li:last-child {
    text-align: left; }
  .business-render .review-detail ul li {
    width: 100%; }
  .review-detail ul li:last-child {
    padding-left: 0; }
  .review-detail ul li:first-child {
    width: auto; }
  .review-detail .times-hired {
    text-align: right; }
  .tabbing-block .nav-tabs .nav-link {
    min-width: auto;
    font-size: 13px; }
  .error-title img {
    width: 250px; }
  .contact-detail-pic {
    height: 300px;
    width: 100%; }
  .search-result-left, .search-result-right {
    display: block !important;
    margin-bottom: 20px; }
  .search-count {
    padding: 0 15px;
    margin-top: 0px; }
  .categoryblock-img, .categoryblock-img img {
    width: 100%; }
  .categoryservice-block {
    width: 100%;
    margin-left: 0;
    margin-top: 0; }
  .moreinfo .date {
    text-align: left;
    width: 100%;
    margin: 4px 0; }
  .more_details .date {
    width: auto; }
  .more_details .locationname, .phonename {
    width: auto !important;
    padding-right: 10px; }
  .more_details .address-timeblock {
    position: relative; }
  .more_details .address-timeblock .icon-locationname {
    padding-left: 23px;
    padding-bottom: 5px; }
  .more_details .address-timeblock .locationname img {
    position: absolute;
    left: 2px;
    top: 5px; }
  .inner-banner-section {
    height: 380px; }
  .inner-banner-section .home-form-block {
    display: inline-block;
    margin-top: 90px; }
  .inner-section {
    margin: 20px 0 110px 0; }
  .navbar-nav {
    padding-bottom: 0; }
  .half-pic-block {
    height: 200px; }
  .half-pic-block:after {
    display: none; }
  .date .btn {
    float: right; }
  .forms .modal-footer .btn {
    margin-left: 0 !important;
    text-align: center;
    float: none !important; }
  .back_btn_mob {
    display: block;
    margin-bottom: 20px;
    color: #fff;
    line-height: 48px;
    margin-right: 20px; }
  .messagelist .back_btn_mob {
    display: block;
    float: left;
    color: #fff;
    line-height: 48px;
    margin-right: 10px;
    margin-bottom: 0; }
  .leftside-desktop, .left-sidebar, .right-sidebar {
    display: none; }
  .business-render .date a {
    margin-left: 10px;
    min-width: 120px;
    margin-bottom: 40px; }
  .right-sidebarblock {
    padding-left: 15px; }
  .searchresult-categoryblock {
    padding: 15px; }
  .rightchatbarmobileview, .leftchatbarMobileView {
    display: none; }
  .singlebusiness-block .map-location {
    margin: 0; }
  .business-listing.business-render .edit-btn {
    top: auto;
    bottom: 10px;
    left: 20px;
    right: auto;
    padding: 0 12px !important; }
  .business-brand-detail ul {
    padding-bottom: 70px; }
  .review-service-list ul li {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .review-service-list .his-servicename {
    text-align: center;
    max-width: initial; }
  body.modal-open .inner-header .sticky-top {
    left: 0;
    padding-left: 15px; }
  .white-bg {
    padding: 0;
    box-shadow: none; }
  .messagelist .chaticon-block .profile-item .dropdown-menu {
    right: -93px !important; }
  .messagelist .mob-menu .profile-item .dropdown-menu {
    width: 330px;
    transform: translate3d(30px, -112px, 0px) !important; }
  .messagelist .mob-menu .profile-item .dropdown-menu li {
    width: 50%;
    float: left;
    padding: 10px 0 10px 30px; }
  .messagelist .mob-menu .profile-item .dropdown-menu li:nth-child(2) {
    border-left: 1px solid #e9ebf2; }
  .messagelist .mob-menu .profile-item .dropdown-menu li:nth-child(4) {
    border-left: 1px solid #e9ebf2; }
  .messagelist .mob-menu .profile-item .dropdown-menu li:nth-child(3) {
    border-bottom: 0; }
  .messagelist .chatHeader .userchat-title .chat-title {
    font-size: 13px; }
  .messagelist .chatHeader .back_btn_mob {
    margin-bottom: 0;
    margin-right: 4px; }
  .audio.react-audio-player {
    width: 180px !important; }
  .react-h5-audio-player.undefined > div {
    background-color: transparent !important;
    float: left;
    width: 180px !important;
    box-shadow: none !important;
    height: 50px !important;
    padding: 5px 0 !important; }
  .react-h5-audio-player.undefined {
    width: 230px; }
  .account-detail ul li:after
.attacheicon .account-detail ul li:after {
    left: 5px; }
  .singlebusiness-block .searchresult-categoryblock .locationname, .singlebusiness-block .searchresult-categoryblock .phonename {
    width: 100%;
    text-align: left; }
  .serv-name > span {
    max-width: 100%; }
  /*.contact-banner.d-none {
	display: block !important;
}*/
  .search-count .search-countright {
    float: left; }
  .services-block button.slick-arrow.slick-prev {
    left: 5px !important;
    z-index: 1; }
  .services-block button.slick-arrow.slick-next {
    right: 5px !important;
    z-index: 1; }
  .second-header .navbar-nav .nav-item:first-child {
    border-left: none; }
  .search-d-none {
    display: block !important; }
  .more_details {
    margin-top: 30px; }
  .banner-title span {
    display: block !important;
    font-size: 15px; }
  .alert {
    display: none; }
  .main-header .navbar-collapse {
    margin-bottom: 5px; }
  .notification-item .account-detail:before {
    right: 27%; }
  .edit-btn .link {
    margin: 0;
    padding: 0; }
  .img-wrap img {
    width: 72px !important;
    height: 72px !important; }
  .uploaded-img {
    text-align: center; }
  .user-profile-update .modal-body {
    padding: 0; }
  .user-profile-update .delete-profile {
    min-width: 30px;
    padding: 0;
    right: 40px; }
  .gallery-block .beauty-box {
    margin-bottom: 15px; } }

@media only screen and (min-width: 414px) and (max-width: 736px) {
  .user-profile-update .delete-profile {
    min-width: 30px;
    padding: 0;
    right: 60px; } }

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .forms .tabbing-block .nav-tabs .nav-link {
    font-size: 14px;
    line-height: 15px;
    padding-top: 4px; }
  .tabbing-block {
    padding: 0; }
  .Social-icon ul .google-clr {
    margin: 10px 0; }
  .Social-icon ul li {
    float: left;
    width: 100%;
    list-style: none; }
  .Social-icon ul li a {
    padding: 0 10px; }
  .forms .modal-footer {
    text-align: center;
    margin-top: 10px;
    padding: 15px 0; }
  .forms .modal-footer p, .forms .modal-footer .btn {
    float: none !important;
    text-align: center;
    margin-left: 0 !important; }
  .forms .with-signup:before {
    right: 2%; }
  .forms .with-signup:after {
    left: 2%; }
  .checkbox-block .forgot-pass {
    float: right; }
  .business-brand-detail, .business-brand {
    width: 100%; }
  .business-brand {
    height: 200px; }
  .business-brand img {
    width: 100%; }
  .date .btn {
    min-width: auto;
    padding: 0 7px !important;
    float: left;
    margin-left: 0; }
  span.brand-icon {
    margin-bottom: 0; }
  .business-brand-detail li {
    margin-right: 8px !important; }
  .sticky-footer ul li button.link a {
    padding: 10px 0;
    font-size: 13px; }
  .sticky-footer .category-item {
    font-size: 8px; }
  .inner-header .navbar-nav.mob-menu .nav-item {
    padding: 0px 8px; }
  .beauty-box .card-title {
    font-size: 20px;
    min-height: 52px; }
  .his-servicename {
    float: left;
    width: 100%;
    text-align: left; }
  .his-servicename span {
    display: none; }
  .address-timeblock .phonename {
    float: left; }
  .messagelist .chaticon-block > ul > li {
    padding: 0 3px; }
  .messagelist .mob-menu .profile-item .dropdown-menu, .messagelist .mob-menu .profile-item .dropdown-menu li {
    border-left: none !important;
    border-right: none !important;
    float: none;
    width: 100%; }
  .messagelist .mob-menu .profile-item .dropdown-menu {
    transform: translate3d(30px, -210px, 0px) !important; } }

/* Landscape phones and smaller */
@media only screen and (max-width: 480px) {
  .forms .tabbing-block .nav-tabs .nav-link {
    font-size: 14px;
    line-height: 15px;
    padding-top: 4px; }
  .business-brand-detail li {
    width: auto;
    text-align: left; }
  .business-listing {
    padding: 18px; }
  .business-render .date a {
    margin-left: 10px;
    min-width: 80px;
    margin-bottom: 40px;
    float: left; }
  .business-listing.business-render .edit-btn {
    padding: 0 !important; }
  .forms .tabbing-block .nav-tabs .nav-link {
    font-size: 15px; } }

/* Portrait phones and smaller */
@media only screen and (max-width: 479px) {
  .review-detail .times-hired {
    float: left;
    text-align: left; } }

/* Portrait phones and smaller */
@media only screen and (max-width: 320px) {
  .checkbox-block .forgot-pass {
    margin-top: 5px;
    font-size: 12px; }
  .p-checkbox + label {
    font-size: 14px; }
  .messagelist .chatHeader .userchat-title {
    width: 80%; }
  .user-profile-update .delete-profile {
    right: 15px; } }

